import settings from 'settings';
import createRequest from 'utils/api/requestFactory';

import { InnovationSimpleFocusArea, InnovationTrainingArticle } from './types';

const { cmsInnovation } = settings.api;

export const getSimpleFocusAreas = async () => {
  const response = await createRequest({
    method: 'get',
    url: `${cmsInnovation}/focus-area/items`,
    errorMessages: {
      '*': 'Failed to get innovation focus areas, please contact support for assistance.',
    },
  });
  if (!response?.data?.data) {
    throw Error();
  }
  return response.data.data as InnovationSimpleFocusArea[];
};

export const getTrainingArticle = async (id: string) => {
  const response = await createRequest({
    method: 'get',
    url: `${cmsInnovation}/learning/training/${id}`,
    errorMessages: {
      '*': 'Failed to get training article, please contact support for assistance.',
    },
  });
  if (!response?.data?.data) {
    throw Error();
  }
  return response.data.data as InnovationTrainingArticle;
};
