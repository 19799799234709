import Stack from '@mui/material/Stack';
import { Theme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { SxProps } from '@mui/system/styleFunctionSx';

import { ProfileMini } from './ProfileMini';

type User = { id: string; name: string };

type ProfileMiniListProps = {
  elementId: string;
  sx?: SxProps<Theme>;
  title?: string;
  users: User[];
  onClick?: (user: User) => void;
};

const ProfileMiniList = ({
  elementId,
  sx,
  title,
  users,
  onClick,
}: ProfileMiniListProps) => {
  return (
    <Stack
      sx={[
        (theme) => ({ gap: theme.spacing('xs') }),
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
    >
      {title && (
        <Typography
          variant="body2"
          sx={(theme) => ({ fontWeight: theme.typography.fontWeightBold })}
        >
          {title}
        </Typography>
      )}
      {users.map((user, index) => (
        <ProfileMini
          key={`${elementId}-${index}`}
          elementId={`${elementId}-${index}`}
          profileId={user.id}
          profileName={user.name}
          onClick={() => onClick && onClick(user)}
        />
      ))}
    </Stack>
  );
};

export { ProfileMiniList };
