import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import OutlinedInput from '@mui/material/OutlinedInput';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { getAuthenticatedVideo } from 'api/cms/creoAuthenticatedVideo';
import { useSnackbar } from 'context';
import { KeyboardKeyEnum } from 'enums';
import { FormFieldWrapper } from 'layout';
import { useCallback, useEffect, useState } from 'react';
import { translations } from 'translations';
import { getService } from 'utils/cms/parseVideoUrls';
import * as formUtils from 'utils/form';

import { Icon } from '../../Icon/Icon';

export type EmbedVideoValue = {
  url: string;
};

type EmbedVideoModalProps = {
  elementId: string;
  defaultValue?: EmbedVideoValue;
  disabled?: boolean;
  onSubmit: (value: EmbedVideoValue) => void;
  onClose: () => void;
};

const EmbedVideoModal = ({
  elementId,
  defaultValue = { url: '' },
  disabled,
  onSubmit,
  onClose,
}: EmbedVideoModalProps) => {
  const [urlInput, setUrlInput] = useState<{
    value: string;
    error: string;
  } | null>(null);

  const { showSnackbar } = useSnackbar();

  const validateUrl = useCallback((value: string) => {
    if (value.trim() !== '' && formUtils.customValidators.url(value)) {
      return '';
    }
    return formUtils.getErrorMessage('url');
  }, []);

  const applyCreoAuthenticationToken = useCallback(async (url: URL) => {
    if (url.href.indexOf('cvm3.se') === -1) {
      return url;
    }
    const response = await getAuthenticatedVideo({ url: url.href });
    if (!response?.data?.data?.url) {
      throw Error();
    }
    return new URL(response.data.data.url);
  }, []);

  const handleSubmit = useCallback(async () => {
    if (!urlInput || urlInput.error) {
      showSnackbar({
        type: 'error',
        text: translations.embedVideoErrorInvalidUrl,
      });
      return;
    }

    let url: URL | null = null;
    try {
      url = new URL(urlInput.value);
    } catch {
      showSnackbar({
        type: 'error',
        text: translations.embedVideoErrorInvalidUrl,
      });
      return;
    }

    const service = getService(url);
    if (!service) {
      showSnackbar({
        type: 'error',
        text: translations.embedVideoErrorInvalidService,
      });
      return;
    }

    try {
      url = await applyCreoAuthenticationToken(url);
    } catch {
      showSnackbar({
        type: 'error',
        text: translations.embedVideErrorInvalidCreoVideo,
      });
      return;
    }

    onSubmit({ url: url.href });
  }, [applyCreoAuthenticationToken, onSubmit, showSnackbar, urlInput]);

  const handlePaste = useCallback(async () => {
    const newValue = (await navigator.clipboard.readText()).trim();
    const newError = validateUrl(newValue);
    setUrlInput({ value: newValue, error: newError });
  }, [validateUrl]);

  useEffect(() => {
    setUrlInput((prevUrlInput) => {
      if (prevUrlInput !== null) {
        return prevUrlInput;
      }
      return { value: defaultValue.url, error: '' };
    });
  }, [defaultValue.url]);

  if (!urlInput) {
    return null;
  }

  return (
    <Dialog
      open
      aria-labelledby={`${elementId}-title`}
      disablePortal
      PaperProps={{
        sx: (theme) => ({
          maxWidth: '100%',
          width: '34.5rem',
          backgroundColor: theme.colors.surface.primary,
          boxShadow: theme.elevation.md,
          borderRadius: theme.border.radius.md,
        }),
      }}
    >
      <DialogContent
        sx={(theme) => ({
          display: 'flex',
          flexDirection: 'column',
          gap: { xs: theme.spacing('md'), md: theme.spacing('sm') },
          padding: theme.spacing('md'),
          overflowX: 'hidden',
        })}
      >
        <Stack sx={{ flexDirection: 'row' }}>
          <Typography variant="h3" id={`${elementId}-title`}>
            {translations.embedVideoTitle}
          </Typography>
          <IconButton
            size="small"
            aria-label={translations.close}
            onClick={onClose}
            sx={{
              alignSelf: 'baseline',
              marginLeft: 'auto',
              padding: 0,
              '&.MuiButtonBase-root.MuiIconButton-root:hover': {
                backgroundColor: 'unset',
              },
            }}
          >
            <Icon type="xMark" color="secondary" />
          </IconButton>
        </Stack>
        <Stack
          sx={(theme) => ({
            flexDirection: { xs: 'column', md: 'row' },
            gap: { xs: theme.spacing('xs'), md: theme.spacing('sm') },
            alignItems: { xs: 'end', md: 'baseline' },
          })}
        >
          <FormFieldWrapper
            id={`${elementId}-url`}
            label={translations.formLabelUrl}
            hideLabel
            error={urlInput.error || ''}
          >
            <OutlinedInput
              size="small"
              placeholder={translations.formPlaceholderUrl}
              value={urlInput.value}
              multiline
              maxRows={10}
              onChange={(e) => {
                const newValue = e.target.value;
                const newError = validateUrl(newValue);
                setUrlInput({ value: newValue, error: newError });
              }}
              onKeyDown={(e) => {
                if (e.key === KeyboardKeyEnum.Enter) {
                  e.preventDefault();
                  handleSubmit();
                }
              }}
            />
          </FormFieldWrapper>
          <Button
            size="medium"
            variant="text"
            disabled={disabled}
            onClick={handlePaste}
          >
            {translations.paste}
          </Button>
        </Stack>
        <Stack
          sx={(theme) => ({
            flexDirection: { xs: 'column', md: 'row' },
            gap: { xs: theme.spacing('xs'), md: theme.spacing('sm') },
            marginTop: theme.spacing('sm'),
            justifyContent: 'end',
          })}
        >
          <Button
            size="medium"
            variant="text"
            disabled={disabled}
            onClick={onClose}
          >
            {translations.cancel}
          </Button>
          <Button
            size="medium"
            variant="contained"
            disabled={disabled || !urlInput.value || Boolean(urlInput.error)}
            onClick={handleSubmit}
          >
            {translations.embedVideoSubmit}
          </Button>
        </Stack>
      </DialogContent>
    </Dialog>
  );
};

export { EmbedVideoModal };
