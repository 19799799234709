import 'react-image-crop/dist/ReactCrop.css';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import OutlinedInput from '@mui/material/OutlinedInput';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useSnackbar } from 'context';
import { FormFieldHelper, FormFieldWrapper } from 'layout';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import ReactCrop, { PercentCrop } from 'react-image-crop';
import { translations } from 'translations';
import * as formUtils from 'utils/form';
import * as textUtils from 'utils/misc/text';

import { Icon } from '../../Icon/Icon';
import * as imageHelpers from '../../Image/helpers';
import * as helpers from './helpers';
import { BlobImage, ImageUploaded } from './helpers';

export type ImageDetailsValue = (BlobImage | ImageUploaded) & {
  altText: string;
};

export type ImageDetailsModalProps = {
  elementId: string;
  defaultValue: ImageDetailsValue;
  aspectRatio?: number;
  minWidth?: number;
  minHeight?: number;
  maxSizeBytes?: number;
  disabled?: boolean;
  hideAltText?: boolean;
  onSubmit: (value: ImageDetailsValue) => void;
  onClose: () => void;
};

const ImageDetailsModal = ({
  elementId,
  defaultValue,
  aspectRatio,
  minWidth: minWidthProp,
  minHeight: minHeightProp,
  maxSizeBytes,
  disabled,
  hideAltText,
  onClose,
  onSubmit,
}: ImageDetailsModalProps) => {
  const [altTextError, setAltTextError] = useState<string>('');
  const [altTextInput, setAltTextInput] = useState<string>('');

  const [imageError, setImageError] = useState<string>('');
  const [imageInput, setImageInput] = useState<
    BlobImage | ImageUploaded | null
  >(null);

  const [crop, setCrop] = useState<PercentCrop | undefined>(undefined);

  const imgRef = useRef<HTMLImageElement | null>(null);

  const { showSnackbar } = useSnackbar();

  const { minWidth, minHeight } = useMemo(
    () =>
      helpers.getValidMinDimensions({
        minWidth: minWidthProp,
        minHeight: minHeightProp,
        aspectRatio,
      }),
    [aspectRatio, minHeightProp, minWidthProp]
  );

  const disableCrop = useMemo(
    () =>
      defaultValue.type === 'uploaded' ||
      (defaultValue.width &&
        defaultValue.height &&
        (defaultValue.width <= minWidth || defaultValue.height <= minHeight)),
    [
      defaultValue.type,
      defaultValue.width,
      defaultValue.height,
      minWidth,
      minHeight,
    ]
  );

  const validateImage = useCallback(
    (image: BlobImage | ImageUploaded) => {
      if (disableCrop) {
        return null;
      }
      if (image.width < minWidth || image.height < minHeight) {
        return textUtils.replaceTranslationAliases(
          translations.imageInputInvalidDimensions,
          { minWidth, minHeight }
        );
      }
      return null;
    },
    [disableCrop, minHeight, minWidth]
  );

  const validateAltText = useCallback(
    (altText: string) => {
      if (hideAltText) {
        return null;
      }
      if (altText.trim() === '') {
        return formUtils.getErrorMessage('required', {
          displayName: translations.imageInputAltTitle,
        });
      }
      return null;
    },
    [hideAltText]
  );

  const handleSubmit = useCallback(async () => {
    if (!imageInput || !imgRef.current) {
      return;
    }

    const newImage = await helpers.cropAndCompressImage({
      imageElement: imgRef.current,
      maxSizeBytes,
      crop,
    });

    const newAltTextError = validateAltText(altTextInput);
    const newImageError = validateImage(newImage);

    if (newAltTextError || newImageError) {
      setAltTextError(
        (prevAltTextError) => newAltTextError || prevAltTextError
      );
      setImageError((prevImageError) => newImageError || prevImageError);
      return;
    }
    onSubmit({ altText: altTextInput, ...newImage });
  }, [
    altTextInput,
    crop,
    imageInput,
    maxSizeBytes,
    onSubmit,
    validateAltText,
    validateImage,
  ]);

  const handleCropImageLoad = useCallback(
    (e: React.SyntheticEvent<HTMLImageElement, Event>) => {
      const image = e.currentTarget;
      const initCrop = helpers.getInitialCrop({
        imageWidth: image.naturalWidth,
        imageHeight: image.naturalHeight,
        minWidth,
        minHeight,
        aspectRatio,
      });
      setCrop(initCrop);
    },
    [aspectRatio, minHeight, minWidth]
  );

  useEffect(() => {
    if (imageInput) {
      return;
    }
    if (defaultValue.altText) {
      setAltTextInput(defaultValue.altText);
    }
    if (defaultValue.type === 'uploaded') {
      setImageInput({
        type: defaultValue.type,
        src: defaultValue.src,
        width: defaultValue.width,
        height: defaultValue.height,
      });
      return;
    }
    setImageInput({
      type: defaultValue.type,
      src: defaultValue.src,
      width: defaultValue.width,
      height: defaultValue.height,
      blob: defaultValue.blob,
    });
  }, [defaultValue, imageInput]);

  if (!imageInput) {
    return null;
  }

  const minWidthCropPx =
    minWidth && imgRef.current
      ? (minWidth / imgRef.current.naturalWidth) * imgRef.current.width
      : 0;

  const minHeightCropPx =
    minHeight && imgRef.current
      ? (minHeight / imgRef.current.naturalHeight) * imgRef.current.height
      : 0;

  return (
    <Dialog
      open
      aria-labelledby={`${elementId}-title`}
      disablePortal
      PaperProps={{
        sx: (theme) => ({
          maxWidth: '100%',
          backgroundColor: theme.colors.surface.primary,
          boxShadow: theme.elevation.md,
          borderRadius: theme.border.radius.md,
        }),
      }}
    >
      <DialogContent
        sx={(theme) => ({
          display: 'flex',
          flexDirection: 'column',
          gap: { xs: theme.spacing('md'), md: theme.spacing('sm') },
          padding: theme.spacing('md'),
          overflowX: 'hidden',
        })}
      >
        <Stack sx={{ flexDirection: 'row' }}>
          <Typography variant="h3" id={`${elementId}-title`}>
            {disableCrop
              ? translations.mediaInputImage
              : translations.imageInputCropTitle}
          </Typography>
          <IconButton
            size="small"
            aria-label={translations.close}
            onClick={onClose}
            sx={{
              alignSelf: 'baseline',
              marginLeft: 'auto',
              padding: 0,
              '&.MuiButtonBase-root.MuiIconButton-root:hover': {
                backgroundColor: 'unset',
              },
            }}
          >
            <Icon type="xMark" color="secondary" />
          </IconButton>
        </Stack>
        <Stack
          sx={(theme) => ({
            flexDirection: { xs: 'column', md: 'row' },
            gap: theme.spacing('sm'),
          })}
        >
          <Stack
            sx={(theme) => ({
              maxWidth: { xs: '100%', md: '65%' },
              gap: theme.spacing('xxxs'),
              flexShrink: 0,
              '> .ReactCrop': {
                borderRadius: theme.border.radius.lg,
                overflow: 'hidden',
              },
              img: {
                borderRadius: theme.border.radius.lg,
                maxHeight: '70vh',
                maxWidth: '100%',
              },
              '> .ReactCrop .ReactCrop__crop-selection': {
                outline: `2px dashed ${theme.colors.border.focus}`,
                borderRadius: theme.border.radius.lg,
                border: 'none',
                animation: 'none',
                background: 'unset',
              },
            })}
          >
            {disableCrop ? (
              <Box
                ref={imgRef}
                component="img"
                src={
                  imageInput.type === 'blob'
                    ? imageInput.src
                    : imageHelpers.getSrc({ pathOrUrl: imageInput.src })
                        .originalSrc
                }
                alt={altTextInput}
                title={altTextInput}
                onError={() =>
                  showSnackbar({
                    type: 'error',
                    text: translations.imageInputUploadError,
                  })
                }
              />
            ) : (
              <ReactCrop
                crop={crop}
                keepSelection
                aspect={aspectRatio}
                minWidth={minWidthCropPx}
                minHeight={minHeightCropPx}
                onChange={(_, percentCrop) => setCrop(percentCrop)}
                onComplete={(_, percentCrop) => setCrop(percentCrop)}
              >
                <Box
                  ref={imgRef}
                  component="img"
                  src={imageInput.src}
                  alt={altTextInput}
                  title={altTextInput}
                  onLoad={handleCropImageLoad}
                  onError={() =>
                    showSnackbar({
                      type: 'error',
                      text: translations.imageInputUploadError,
                    })
                  }
                />
              </ReactCrop>
            )}
            <FormFieldHelper type="critical" open={Boolean(imageError)}>
              {imageError}
            </FormFieldHelper>
          </Stack>
          <Stack
            sx={(theme) => ({
              gap: { xs: theme.spacing('xs'), md: theme.spacing('sm') },
            })}
          >
            <Typography
              variant="body2"
              sx={(theme) => ({
                fontWeight: theme.typography.fontWeightBold,
                color: theme.colors.text.primary,
              })}
            >
              {translations.imageInputAltTitle}
            </Typography>
            <Typography
              variant="caption"
              sx={(theme) => ({
                color: theme.colors.text.secondary,
              })}
            >
              {translations.imageInputAltDescription}
            </Typography>
            {!hideAltText && (
              <FormFieldWrapper
                id={`${elementId}-alt-text`}
                label={translations.imageInputAltDescription}
                hideLabel
                error={altTextError}
              >
                <OutlinedInput
                  fullWidth
                  multiline
                  size="small"
                  inputProps={{ maxLength: 125 }}
                  minRows={3}
                  maxRows={8}
                  placeholder={translations.imageInputAltTitle}
                  value={altTextInput}
                  error={Boolean(altTextError)}
                  onChange={(e) => {
                    const newValue = e.target.value;
                    setAltTextInput(newValue);
                    const error = validateAltText(newValue);
                    setAltTextError(error || '');
                  }}
                  sx={(theme) => ({
                    backgroundColor: theme.colors.surface.secondary,
                    '.MuiOutlinedInput-notchedOutline': {
                      border: `1px dashed ${theme.colors.border.input}`,
                    },
                  })}
                />
              </FormFieldWrapper>
            )}
          </Stack>
        </Stack>
        <Stack
          sx={(theme) => ({
            flexDirection: { xs: 'column', md: 'row' },
            gap: { xs: theme.spacing('xs'), md: theme.spacing('sm') },
            justifyContent: 'end',
          })}
        >
          <Button
            size="medium"
            variant="text"
            disabled={disabled}
            onClick={onClose}
          >
            {translations.cancel}
          </Button>
          <Button
            size="medium"
            variant="contained"
            disabled={disabled}
            onClick={handleSubmit}
          >
            {translations.imageInputSubmit}
          </Button>
        </Stack>
      </DialogContent>
    </Dialog>
  );
};

export { ImageDetailsModal };
