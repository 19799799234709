import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import { FolderItem } from 'api/sharepointdocuments/types';
import { useMemo } from 'react';

import { Icon } from '../../../componentsNew/Icon/Icon';
import { LinkList, LinkListItem } from '../../../componentsNew/LinkList';

type Link = { title: string; url: string };

export type ResourcesListProps = {
  elementId: string;
  links?: Link[];
  documentsFolderId?: string;
  documents: FolderItem[];
  isDocumentsLoading?: boolean;
  onLinkClick?: (link: Link) => void;
  onDocumentClick?: (document: FolderItem) => void;
};

const ResourcesList = ({
  elementId,
  links = [],
  isDocumentsLoading,
  documents,
  onLinkClick,
  onDocumentClick,
}: ResourcesListProps) => {
  const listItems = useMemo(() => {
    const _listItems: LinkListItem[] = [];
    if (!links && !documents) {
      return _listItems;
    }
    if (links) {
      const linkItems = links.map((link, index) => {
        const item: LinkListItem = {
          id: `${elementId}-link-${index}`,
          href: link.url,
          subDescriptions: [link.title],
          startElement: <Icon type="link" size={24} color="secondary" />,
          onClick: () => onLinkClick && onLinkClick(link),
        };
        return item;
      });
      _listItems.push(...linkItems);
    }
    if (documents) {
      const documentItems = documents.map((document, index) => {
        const item: LinkListItem = {
          id: `${elementId}-document-${index}`,
          href: document.downloadUrl,
          subDescriptions: [document.name],
          startElement: (
            <Icon type="arrowDownTray" size={24} color="secondary" />
          ),
          onClick: () => onDocumentClick && onDocumentClick(document),
        };
        return item;
      });
      _listItems.push(...documentItems);
    }
    return _listItems;
  }, [documents, links, elementId, onDocumentClick, onLinkClick]);

  if (!listItems.length) {
    return null;
  }

  return (
    <Stack sx={(theme) => ({ gap: theme.spacing('xxs') })}>
      <LinkList
        sx={(theme) => ({
          '.MuiLink-root': {
            textDecoration: 'underline',
            textDecorationColor: theme.colors.text.tertiary,
            padding: theme.spacing('xxs'),
            '&:hover': {
              textDecorationColor: theme.colors.text.actionHover,
              svg: { color: theme.colors.text.actionHover },
            },
          },
        })}
        items={listItems}
      />
      {links.length > 0 && isDocumentsLoading && (
        <>
          {Array(3)
            .fill(0)
            .map((_, i) => (
              <Skeleton
                key={`${elementId}-skeleton-${i}`}
                variant="rounded"
                animation="wave"
                sx={() => ({ height: '2.5rem' })}
              />
            ))}
        </>
      )}
    </Stack>
  );
};

export { ResourcesList };
