import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { useMemo, useState } from 'react';

import { MediaActionButtons } from '../MediaActionButtons';
import { ImageDetailsModal, ImageDetailsValue } from './ImageDetailsModal';

type ImageEditProps = {
  elementId: string;
  value: ImageDetailsValue;
  aspectRatio?: number;
  minWidth?: number;
  minHeight?: number;
  maxSizeBytes?: number;
  onChange: (value: ImageDetailsValue) => void;
  onDelete?: () => void;
};

const ImageEdit = ({
  elementId,
  value,
  aspectRatio,
  minWidth,
  minHeight,
  maxSizeBytes,
  onChange,
  onDelete,
}: ImageEditProps) => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [isPreviewLoaded, setIsPreviewLoaded] = useState<boolean>(false);

  const preview = useMemo(() => {
    return (
      <Box
        component="img"
        src={value.src}
        alt={value.altText}
        title={value.altText}
        sx={(theme) => ({ borderRadius: theme.border.radius.lg })}
        onLoad={() => setIsPreviewLoaded(true)}
      />
    );
  }, [value.altText, value.src]);

  return (
    <>
      {isModalOpen && (
        <ImageDetailsModal
          elementId={`${elementId}-modal`}
          defaultValue={value}
          aspectRatio={aspectRatio}
          minWidth={minWidth}
          minHeight={minHeight}
          maxSizeBytes={maxSizeBytes}
          onClose={() => setIsModalOpen(false)}
          onSubmit={(newValue) => {
            onChange(newValue);
            setIsModalOpen(false);
          }}
        />
      )}
      <Stack sx={() => ({ position: 'relative' })}>
        {preview}
        {isPreviewLoaded && (
          <MediaActionButtons
            onChange={() => setIsModalOpen(true)}
            onDelete={onDelete}
          />
        )}
      </Stack>
    </>
  );
};
export { ImageEdit };
