import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import parse, {
  DOMNode,
  Element,
  HTMLReactParserOptions,
} from 'html-react-parser';
import domToReact from 'html-react-parser/lib/dom-to-react';
import { useCallback, useMemo } from 'react';
import { isVideoImageUrl } from 'utils/cms/parseVideoUrls';

import { EmbedVideo } from '../Embed/EmbedVideo';
import { Image } from '../Image/Image';
import { WhitelistValidationLink } from '../WhitelistValidationLink';
import { BlockQuote } from './BlockQuote';

type HtmlParserProps = {
  html: string;
};

const HtmlParser = ({ html }: HtmlParserProps) => {
  const a = useCallback((node: Element) => {
    if (!node.attribs['href']) {
      return domToReact(node.children as DOMNode[]);
    }

    return (
      <WhitelistValidationLink
        id={node.attribs['id']}
        href={node.attribs['href']}
        referrerPolicy="no-referrer"
        sx={{ wordBreak: 'break-word' }}
        target="_blank"
      >
        {domToReact(node.children as DOMNode[])}
      </WhitelistValidationLink>
    );
  }, []);

  const blockquote = useCallback((node: Element) => {
    return <BlockQuote>{domToReact(node.children as DOMNode[])}</BlockQuote>;
  }, []);

  const h2 = useCallback((node: Element) => {
    return (
      <Typography variant="h2">
        {domToReact(node.children as DOMNode[])}
      </Typography>
    );
  }, []);

  const iframe = useCallback((node: Element) => {
    const src = node.attribs['src'];
    if (!src) {
      return domToReact(node.children as DOMNode[]);
    }
    if (isVideoImageUrl(src)) {
      return <EmbedVideo title={node.attribs['title']} src={src} />;
    }
    return domToReact(node.children as DOMNode[]);
  }, []);

  const img = useCallback((node: Element) => {
    if (
      !node.attribs['src'] ||
      !node.attribs['data-original-height'] ||
      !node.attribs['data-original-width']
    ) {
      return domToReact(node.children as DOMNode[]);
    }
    return (
      <Image
        alt={node.attribs['alt']}
        title={node.attribs['alt']}
        src={node.attribs['src']}
        originalHeight={Number(node.attribs['data-original-height'])}
        originalWidth={Number(node.attribs['data-original-width'])}
      />
    );
  }, []);

  const options = useMemo(() => {
    const replace: HTMLReactParserOptions['replace'] = (node: DOMNode) => {
      if (!(node instanceof Element)) {
        return;
      }
      switch (node.name) {
        case 'a':
          return a(node);
        case 'blockquote':
          return blockquote(node);
        case 'h2':
          return h2(node);
        case 'iframe':
          return iframe(node);
        case 'img':
          return img(node);
        default:
          return;
      }
    };
    return { replace };
  }, [a, blockquote, h2, iframe, img]);

  return <Box sx={{ wordBreak: 'break-word' }}>{parse(html, options)}</Box>;
};

export { HtmlParser };
