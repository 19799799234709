import settings from 'settings';

const { cms, cdn } = settings.api;
const { cdnTokenKey } = settings.misc;

const getSrc = (params: {
  pathOrUrl: string;
  originalWidth?: number;
  originalHeight?: number;
}) => {
  const { pathOrUrl, originalWidth, originalHeight } = params;

  const isCmsImage = /\/static\/node\//.test(pathOrUrl);
  if (!isCmsImage) {
    return { originalSrc: pathOrUrl };
  }
  const isUrl = /^(https?:\/\/)/.test(pathOrUrl);
  const url = isUrl ? pathOrUrl : `${BASE_PATH}${pathOrUrl}`;

  if (!originalHeight || !originalWidth) {
    return { originalSrc: addCdnToken(url) };
  }

  const preloadSize =
    originalHeight > originalWidth
      ? IMAGE_SIZES_PORTRAIT[0]
      : IMAGE_SIZES_LANDSCAPE[0];

  const preloadUrl = `${url}/${preloadSize}`;
  const isPreloadUrlSameAsOriginalUrl = preloadUrl === url;
  const isPreloadSizeLargerThanOriginalSize = originalWidth < preloadSize;

  if (isPreloadUrlSameAsOriginalUrl || isPreloadSizeLargerThanOriginalSize) {
    return { originalSrc: addCdnToken(url) };
  }
  return {
    originalSrc: addCdnToken(url),
    preloadSrc: addCdnToken(preloadUrl),
  };
};

const getSrcSet = (params: {
  src: string;
  originalWidth?: number;
  originalHeight?: number;
}) => {
  const { src, originalWidth, originalHeight } = params;

  if (!originalHeight || !originalWidth) {
    return;
  }

  const isPortrait = originalHeight > originalWidth;

  const availableSizes = isPortrait
    ? IMAGE_SIZES_PORTRAIT.filter((size) => size <= originalWidth)
    : IMAGE_SIZES_LANDSCAPE.filter((size) => size <= originalWidth);

  const srcSetParts = availableSizes.map((size) => {
    const urlWithSizeAndToken = addCdnToken(`${src}/${size}`);
    return `${urlWithSizeAndToken} ${size}w`;
  });
  return srcSetParts.join(', ');
};

const addCdnToken = (url: string) => {
  return cdn && cdnTokenKey ? `${url}?${cdnTokenKey}` : url;
};

const getBasePath = () => {
  if (cdn) {
    return cdn as string;
  }
  if (cms) {
    return cms.replace('/api', '') as string;
  }
  return null;
};

const BASE_PATH = getBasePath();

export const IMAGE_SIZES_LANDSCAPE = [300, 480, 768, 1000, 1500, 2000];
export const IMAGE_SIZES_PORTRAIT = [450, 610, 1200];

export { addCdnToken, getSrc, getSrcSet };
