import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import { useTheme } from '@mui/material/styles';
import { Theme } from '@mui/material/styles';
import { CSSProperties } from '@mui/material/styles/createTypography';
import Typography from '@mui/material/Typography';
import { SxProps } from '@mui/system/styleFunctionSx';
import { Icon } from 'componentsNew';
import { useMemo } from 'react';
import { translations } from 'translations';

import { ReactComponent as BellBadgeLarge } from './icons/BellBadgeLarge.svg';
import { ReactComponent as BellBadgeSmall } from './icons/BellBadgeSmall.svg';
import { ReactComponent as CheckCircleBadgeLarge } from './icons/CheckCircleBadgeLarge.svg';
import { ReactComponent as CheckCircleBadgeSmall } from './icons/CheckCircleBadgeSmall.svg';
import { ReactComponent as ExclamationTriangleBadgeLarge } from './icons/ExclamationTriangleBadgeLarge.svg';
import { ReactComponent as ExclamationTriangleBadgeSmall } from './icons/ExclamationTriangleBadgeSmall.svg';
import { ReactComponent as InformationCircleBadgeLarge } from './icons/InformationCircleBadgeLarge.svg';
import { ReactComponent as InformationCircleBadgeSmall } from './icons/InformationCircleBadgeSmall.svg';

type AlertDialogProps = {
  id?: string;
  open: boolean;
  type?: 'information' | 'success' | 'warning' | 'critical';
  size?: 'small' | 'large';
  icon?: React.ReactNode;
  title?: string;
  paragraphs?: string[];
  primaryButton?: {
    text: string;
    loading?: boolean;
    disabled?: boolean;
    onClick: () => void;
  };
  secondaryButton?: {
    text: string;
    loading?: boolean;
    disabled?: boolean;
    onClick: () => void;
  };
  tertiaryButton?: {
    text: string;
    loading?: boolean;
    disabled?: boolean;
    onClick: () => void;
  };
  disableClose?: boolean;
  sx?: SxProps<Theme>;
  children?: React.ReactNode | React.ReactNode[];
  onClose?: () => void;
};

const AlertDialog = ({
  id,
  open,
  type = 'information',
  size = 'large',
  icon,
  title,
  paragraphs = [],
  primaryButton,
  secondaryButton,
  tertiaryButton,
  disableClose,
  sx,
  children,
  onClose,
}: AlertDialogProps) => {
  const theme = useTheme();

  const iconSettings: {
    element: React.ReactNode;
  } = useMemo(() => {
    const isLarge = size === 'large';
    switch (type) {
      case 'success':
        return {
          element: icon ? (
            icon
          ) : isLarge ? (
            <CheckCircleBadgeLarge />
          ) : (
            <CheckCircleBadgeSmall />
          ),
        };
      case 'warning':
        return {
          element: icon ? (
            icon
          ) : isLarge ? (
            <BellBadgeLarge />
          ) : (
            <BellBadgeSmall />
          ),
        };
      case 'critical':
        return {
          element: icon ? (
            icon
          ) : isLarge ? (
            <ExclamationTriangleBadgeLarge />
          ) : (
            <ExclamationTriangleBadgeSmall />
          ),
        };
      default:
        return {
          element: icon ? (
            icon
          ) : isLarge ? (
            <InformationCircleBadgeLarge />
          ) : (
            <InformationCircleBadgeSmall />
          ),
        };
    }
  }, [size, type, icon]);

  const textSettings: {
    titleFallback: string;
    titleColor: string;
    titleTypography: CSSProperties;
    paragraphTypography: CSSProperties;
  } = useMemo(() => {
    const isLarge = size === 'large';

    const titleTypography = isLarge ? theme.typography.h3 : theme.typography.h4;

    const paragraphTypography = isLarge
      ? theme.typography.body1
      : theme.typography.body2;

    switch (type) {
      case 'success':
        return {
          titleFallback: translations.successful,
          titleColor: theme.colors.text.success,
          titleTypography,
          paragraphTypography,
        };
      case 'warning':
        return {
          titleFallback: translations.warning,
          titleColor: theme.colors.text.primary,
          titleTypography,
          paragraphTypography,
        };
      case 'critical':
        return {
          titleFallback: translations.error,
          titleColor: theme.colors.text.critical,
          titleTypography,
          paragraphTypography,
        };
      default:
        return {
          titleFallback: translations.information,
          titleColor: theme.colors.text.brand,
          titleTypography,
          paragraphTypography,
        };
    }
  }, [size, theme, type]);

  const paperSettings: {
    width: string;
    padding: string;
    gap: string;
  } = useMemo(() => {
    switch (size) {
      case 'small':
        return {
          width: '22.5rem',
          padding: theme.spacing('sm'),
          gap: theme.spacing('xs'),
        };
      default:
        return {
          width: '34.5rem',
          padding: theme.spacing('md'),
          gap: theme.spacing('sm'),
        };
    }
  }, [size, theme]);

  return (
    <Dialog
      id={id}
      open={open}
      onClose={disableClose ? undefined : onClose}
      sx={sx}
      PaperProps={{
        sx: (theme) => ({
          width: paperSettings.width,
          padding: paperSettings.padding,
          gap: paperSettings.gap,
          borderRadius: theme.border.radius.md,
        }),
      }}
    >
      <Stack
        sx={(theme) => ({
          flexDirection: 'row',
          gap: theme.spacing('sm'),
        })}
      >
        <Box sx={() => ({ flexShrink: 0, '> svg': { display: 'block' } })}>
          {iconSettings.element}
        </Box>
        <Typography
          component="h2"
          sx={() => ({
            typography: textSettings.titleTypography,
            color: textSettings.titleColor,
            wordBreak: 'break-word',
            margin: 'auto 0',
          })}
        >
          {title || textSettings.titleFallback}
        </Typography>
        {onClose && (
          <IconButton
            size="large"
            aria-label={translations.close}
            sx={(theme) => ({
              marginLeft: 'auto',
              padding: theme.spacing('xxxs'),
              alignSelf: 'baseline',
            })}
            disabled={disableClose}
            onClick={onClose}
          >
            <Icon type="xMark" color="secondary" />
          </IconButton>
        )}
      </Stack>
      {paragraphs &&
        paragraphs.map((paragraph, index) => (
          <Typography
            key={`alert-dialog-paragraph-${index}`}
            sx={(theme) => ({
              color: theme.colors.text.tertiary,
              typography: textSettings.paragraphTypography,
            })}
          >
            {paragraph}
          </Typography>
        ))}
      {children}
      {(primaryButton || secondaryButton || tertiaryButton) && (
        <Stack
          sx={(theme) => ({
            flexDirection: { xs: 'column', md: 'row' },
            justifyContent: 'end',
            gap: theme.spacing('sm'),
            flexWrap: 'wrap',
          })}
        >
          {tertiaryButton && (
            <Button
              size="medium"
              variant="text"
              disabled={tertiaryButton.disabled}
              onClick={tertiaryButton.onClick}
            >
              {tertiaryButton.loading ? (
                <CircularProgress size={20} />
              ) : (
                tertiaryButton.text
              )}
            </Button>
          )}
          {secondaryButton && (
            <Button
              size="medium"
              variant="outlined"
              disabled={secondaryButton.disabled}
              onClick={secondaryButton.onClick}
            >
              {secondaryButton.loading ? (
                <CircularProgress size={20} />
              ) : (
                secondaryButton.text
              )}
            </Button>
          )}
          {primaryButton && (
            <Button
              size="medium"
              variant="contained"
              disabled={primaryButton.disabled}
              onClick={primaryButton.onClick}
            >
              {primaryButton.loading ? (
                <CircularProgress size={20} />
              ) : (
                primaryButton.text
              )}
            </Button>
          )}
        </Stack>
      )}
    </Dialog>
  );
};
export { AlertDialog };
