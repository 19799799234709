import { Translations } from './translations';

const en: Translations = {
  add: 'Add',
  additionalFeedbackReceiversHeaderText: 'Notifications about comments',
  additionalFeedbackReceiversInfoText:
    'The person who creates and edits this article will get notifications about comments. Here you can add additional people who should also get notifications about comments. (Maximum 5)',
  admin: 'Admin',
  aiChatAlertIncompleteProfileText:
    'It seems you have not been assigned to a division or country. Please contact support for assistance.',
  aiChatAlertIncompleteProfileTitle: 'Incomplete profile information',
  aiChatButton: 'Open AI chat',
  aiChatButtonHint: 'Ask our new AI',
  aiChatClear: 'Clear conversation',
  aiChatCopyResponse: 'Copy response',
  aiChatActionDislikeDisclaimer:
    "I'm sorry if my response wasn't what you were looking for! It could be that the information you are looking for is not yet published on Avenue.",
  aiChatDisclaimer:
    'AI can make mistakes. Make sure to double check important information.',
  aiChatDislikeResponse: 'Unhappy with response',
  aiChatErrorGeneric: 'Abby was unable to answer, please try again later.',
  aiChatFeedbackButton: 'Feedback',
  aiChatInputPlaceholder: 'Ask Abby',
  aiChatIntroListTitle: 'Types of questions you can ask me:',
  aiChatIntroListText1: 'How should the ASSA ABLOY logo be used?',
  aiChatIntroListText2: 'Where can I find the e-business training?',
  aiChatIntroListText3: 'Are there any jobs to apply for in Finland?',
  aiChatIntroTitle: 'Hi, I am Abby.',
  aiChatIntroText1: 'I can help you find information from Avenue.',
  aiChatIntroText2: 'Ask me anything, in any language you want.',
  aiChatLikeResponse: 'Happy with response',
  aiChatTitle: 'Abby',
  aiChatSend: 'Send',
  aiChatSourceList: 'Sources',
  aiChatSubtitle: 'Beta',
  alert: 'Alert',
  alertCreateError: 'Something went wrong when creating the alert',
  alertCreateSuccess: 'Successfully created alert',
  allDivisions: 'All divisions',
  allJobs: 'All Jobs',
  apply: 'Apply',
  articles: 'Articles',
  articleBodyPlaceholder: 'Body',
  articleCategoryPlaceholder: 'Category',
  articleContactPlaceholder: 'Contact',
  articleContentTypeBlog: 'Blog',
  articleContentTypeNews: 'News',
  articleContentTypePress: 'Press',
  articleContentTypeStory: 'Story',
  articleDivisionPlaceholder: 'Division Name',
  articleEndDatePlaceholder: 'End Date',
  articleFeed: 'News feed',
  articleFeedAll: 'All divisions',
  articleFeedbackCommentError:
    'Your feedback failed to be submitted, please try again',
  articleFeedbackCommentSuccess:
    'Your feedback has been sent to the information owner',
  articleFeedbackPlaceholder: 'What is on your mind?',
  articleFeedbackRatingTitle: 'Was this page helpful?',
  articleFeedbackRating0:
    'Share with the information owner that you are not satisfied with the content of the article',
  articleFeedbackRating1:
    'Share with the information owner that you are neither happy nor unhappy with the content of the article',
  articleFeedbackRating2:
    'Share with the information owner that you are happy with the content of the article',
  articleFeedDefault: 'For you',
  articleFeedDepartment: 'Function',
  articleFeedNoDepartmentTextWithLink:
    'We are unable to show you function specific news since you have not selected a function for your profile. Please go to {{Profile settings}} to complete it.',
  articleFeedNoDepartmentTitle: 'Oh no, you are missing out!',
  articleFeedNoItemsTextWithLink:
    'We have nothing to show here right now. Explore news, blogs, stories and more in the {{Feed}}.',
  articleFeedNoItemsTextWithLinkEditor: 'Post news {{here}}!',
  articleFeedNoItemsTitle: 'No news available',
  articleFeedReadMore: 'All News',
  articleFeedReadMoreAriaLabel: 'Go to feed',
  articleFormInformationOwner1: 'Assign owner of article',
  articleFormInformationOwner2:
    'The owner of the article is displayed in the article footer and receives notifications about comments.',
  articlePreamblePlaceholder: 'Preamble (max 300 characters)',
  articleReleaseYearPlaceholder: 'Release Year',
  articleStartDatePlaceholder: 'Start Date',
  articleTitlePlaceholder: 'Title (max 90 characters)',
  back: 'Back',
  businessArea: 'Business area',
  businessUnit: 'Business unit',
  cancel: 'Cancel',
  clear: 'Clear',
  close: 'Close',
  company: 'Company',
  contact: 'Contact',
  comment: 'Comment',
  commentInputTitle: 'Comments',
  commentInputPlaceHolder: 'Post a new comment',
  commentInputSubmit: 'Post Comment',
  commentInputSubmitModalTitle: 'Do you want to publish this comment?',
  commentInputSubmitModalText:
    'This comment will be visible to other employees',
  copy: 'Copy',
  copyConfirmation: 'Copied to clipboard',
  country: 'Country',
  create: 'Create',
  createdByDate: 'Published: {{date}}',
  createdByName: 'Published: {{name}}',
  createdByNameAndDate: 'Published: {{date}} by {{name}}',
  dateTimePickerPlaceholder: 'Select date & time',
  delete: 'Delete',
  department: 'Function',
  division: 'Division',
  divisionGetError: 'Unable to load divisions at the moment',
  downloads: 'Downloads',
  edit: 'Edit',
  editedByDate: 'Last edit: {{date}}',
  editedByName: 'Last edit: {{name}}',
  editedByNameAndDate: 'Last edit: {{date}} by {{name}}',
  email: 'Email',
  embedMsFormIframeTitle: 'Embedded MS Form',
  embedPowerBiIframeTitle: 'Embedded Power BI report',
  embedVideErrorInvalidCreoVideo:
    'Not a valid Creo video. Please check the url and try again.',
  embedVideoErrorInvalidService:
    'Not a valid video provider. Please check the url and try again.',
  embedVideoErrorInvalidUrl:
    'Unable to parse url. Please check the url and try again.',
  embedVideoSubmit: 'Embed video',
  embedVideoTitle: 'Embed video URL',
  error: 'Error',
  externalPersonsInputFormEmail: 'Email',
  externalPersonsInputFormError:
    'You have reached a maximum of {{limit}} persons.',
  externalPersonsInputFormJobTitle: 'Job title',
  externalPersonsInputFormName: 'Name',
  externalPersonsInputFormPlaceholderEmail: 'name@company.com',
  externalPersonsInputFormPlaceholderJobtitle: 'Product owner',
  externalPersonsInputFormPlaceholderName: 'First name & last name',
  feedbackConfirmation: 'Feedback sent',
  fetchError: 'Unable to load content',
  fileSizeBytes: 'Bytes',
  fileSizeKB: 'KB',
  fileSizeMB: 'MB',
  filePickerTitle: 'Browse files',
  fileUploadDialogInfo: "Some files can't be uploaded due to:",
  fileUploadDialogDuplicateNames: 'Duplicate names',
  fileUploadError: 'Show went wrong when attempting to upload file(s)',
  fileUploadFileError: 'Upload failed',
  fileUploadDialogTitle: 'File upload error',
  fileUploadDialogFileTooLarge: 'Exceeding {{size}} MB',
  fileUploadDialogTooManyFiles: 'Too many files',
  fileUploadDialogValidFiles: 'The following files will be uploaded:',
  fileUploadMaxFileSizeMB: 'Maximum file size {{size}} MB',
  fileUploadTitle: 'or drop files to upload',
  fileUploadRemoveError: 'Something went wrong when trying to remove file',
  filter: 'Filter',
  followNicoDelvauxOnLinkedIn: 'Follow Nico Delvaux on LinkedIn',
  formErrorMessageMissingFields: 'The following fields appear to be missing:',
  formInfoCountrySelect: 'Select a division in order to select countries.',
  formLabelButtonLink: 'Button link',
  formLabelButtonText: 'Button text',
  formLabelCountries: 'Countries',
  formLabelDateRange: 'Active period',
  formLabelDescription: 'Description',
  formLabelDivisions: 'Divisions',
  formLabelEndDate: 'End date',
  formLabelImage: 'Image',
  formLabelImageAltText: 'Image alt text',
  formLabelLink: 'Link',
  formLabelOverrideDivisionalMessage: 'Override divisional message',
  formLabelPreview: 'Preview',
  formLabelPublishDate: 'Publish date',
  formLabelPublishTarget: 'Target audience',
  formLabelSize: 'Size',
  formLabelSizeLarge: 'Large',
  formLabelSizeMedium: 'Medium',
  formLabelStartDate: 'Start date',
  formLabelTitle: 'Title',
  formLabelUrl: 'Url',
  formPlaceholderCountry: 'Select country',
  formPlaceholderDivisionOrGlobal: 'Select global or division',
  formPlaceholderEndDate: 'Select end date',
  formPlaceholderStartDate: 'Select start date',
  formPlaceholderUrl: 'https://',
  formValidationPattern: '{{displayName}} is not in a valid format',
  formValidationInvalid: 'Value is invalid',
  formValidationRequired: '{{displayName}} is required',
  formValidationRequiredGeneric: 'Field is required',
  formValidationMaxLength:
    '{{displayName}} can be no more than {{maxLength}} characters',
  formValidationMinLength:
    '{{displayName}} can be no less than {{minLength}} characters',
  formValidationUrl: '{{displayName}} is not a valid URL',
  formValidationFallbackDisplayName: 'Value',
  global: 'Global',
  globalAllEmployees: 'Global (all employees)',
  heroBanner: 'Hero Banner',
  heroBannerCreateError: 'Something went wrong when creating the hero banner',
  heroBannerCreateSuccess: 'Successfully created hero banner',
  heroBannerDeleteError:
    'Something went wrong when trying to delete the hero banner',
  heroBannerDeleteSuccess: 'Successfully deleted hero banner',
  heroBannerDeleteText: 'It will not be possible to restore the content.',
  heroBannerDeleteTitle: 'Are you sure you want to delete this hero banner?',
  heroBannerGetError: 'Something went wrong when fetching hero banner data',
  heroBannerEditError: 'Something went wrong when updating the hero banner',
  heroBannerEditSuccess: 'Successfully updated hero banner',
  hiringManager: 'Hiring manager:',
  imageGalleryBadgeTitle: 'Gallery',
  imageGalleryEditTitle: 'Image Gallery',
  imageGalleryListHeader: 'Images',
  imageGalleryInvalidMimeType: 'File format is not supported',
  imageGalleryInvalidFileSize: 'File size is too large',
  imageGalleryMaxCountTitle: 'Max 10 images! ',
  imageGalleryMaxCountText:
    'A maximum of 10 images will be uploaded to the gallery, any additional images will be ignored.',
  imageGalleryModalNextImage: 'Next image',
  imageGalleryModalPreviousImage: 'Previous image',
  imageInputCropTitle: 'Crop cover',
  imageInputAltTitle: 'Image description',
  imageInputAltDescription:
    'Provide a description of the image to help individuals using screen readers understand its content.',
  imageInputInvalid: 'Upload failed',
  imageInputInvalidDimensions:
    'The image is too small. It must be at least {{minWidth}}px x {{minHeight}}px.',
  imageInputInvalidFileSize:
    'The file size is too large. Please upload an image that is no larger than {{size}}MB.',
  imageInputInvalidFileType:
    'The selected file type is not supported. Please upload a valid image file.',
  imageInputSubmit: 'Save cover',
  imageInputUploadError: 'Failed to load image. Please try again.',
  imagePickerButtonText: 'Images',
  imagePickerInfo1: 'Supported files : jpg, jpeg; PNG',
  imagePickerInfo2: 'Max 10 images, 2Mb per file',
  imageUploadError: 'Failed to upload image, please try again.',
  information: 'Information',
  informationOwner: 'Information Owner',
  innovationDocumentTitle: 'Product Innovation',
  innovationFilterFetchError: 'Unable to load all filters',
  innovationFocusAreas: 'Focus Areas',
  innovationListFetchError: 'Unable to load content',
  innovationTypes: 'Types',
  innovationTypeCaseStudy: 'Case study',
  innovationTypePodcast: 'Podcast',
  innovationTypeTraining: 'Training',
  innovationTypeWebinar: 'Webinar',
  insightsDatabaseTitle: 'Industry Insights',
  insightsDatabaseSubtitle:
    'Unlock industry insights and global awareness with articles from the Insights database',
  insightsDatabaseNoItemsTextWithLink:
    'We have nothing to show here right now. Explore more in {{Insights Database}}.',
  insightsDatabaseReadMore: 'All Insights',
  jobContentFetchError: 'Unable to find job',
  jobListFetchError: 'Unable to load jobs at the moment',
  jobMostRelevantInformation:
    'This job matches your profile´s <strong>location</strong> and <strong>function</strong>.',
  jobOpeningsNoJobs:
    'If you want to explore your career potential in the ASSA ABLOY Group then we are here to support you.',
  jobOpeningsTitle: 'Job openings',
  jobs: 'Jobs',
  jobsApplicationDaysLeftMany: '{{count}} days left to apply',
  jobsApplicationDaysLeftOne: '{{count}} day left to apply',
  jobsApply: 'Apply now',
  jobsEmpty: 'No jobs found',
  jobsEmptyDueToPaginationText: 'Use pagination below to explore jobs',
  jobsEmptyDueToPaginationTitle: 'No job openings found',
  jobsExpandCountries: 'See all countries',
  jobsFilter: 'Filter',
  jobsFilterCategory: 'Function',
  jobsFilterClear: 'Clear all',
  jobsFilterLocation: 'Country',
  jobsFilterFetchError: 'Unable to load filters for jobs',
  jobsFilterRegion: 'Region',
  jobsFilterSeniorityLevel: 'Experience Level',
  jobsFilterState: 'State',
  jobsFilterTitle: 'Filter Jobs',
  jobsInfo:
    'Looking to explore your career potential within ASSA ABLOY and our brands or want to stay informed about internal job opportunities that match your profile?',
  jobsLastApplicationdate: 'Last application date:',
  jobsMultipleLocations: 'Multiple locations',
  jobsNew: 'New',
  jobsTotalMany: '{{count}} Openings',
  jobsTotalOne: '{{count}} Opening',
  jobsTotalZeroText: 'Adjust your filter for better results',
  jobsTotalZeroTitle: 'No job openings found',
  jobsSearchPlaceholder: 'Job search...',
  jobsSortPublished: 'Latest',
  jobsSortRelevance: 'Most relevant',
  jobsStarTooltip: 'Matching your profile',
  jobsSubscribe: 'Subscribe for Job alerts',
  lastEdit: 'Last edit:',
  learnMore: 'Learn more',
  learning: 'Learning',
  learningFilterTitle: 'Filter learning',
  learningListEmpty: 'No content found',
  learningListEmptyDueToPagination: 'Use pagination below to explore content',
  learningListFetchError: 'Unable to fetch content',
  learningSearchPlaceholder: 'Search Learning...',
  libraryMenuFetchError:
    'Unable to load library menu, please refresh the page or try again later',
  like: 'Like',
  links: 'Links',
  loadMoreComments: 'More comments',
  loadMoreReplies: 'More replies',
  market: 'Market',
  manageAlertsActionsDeleteDialogTitle:
    'Are you sure you want to delete the alert(s)?',
  manageAlertsActionsDeleteDialogText:
    'It will not be possible to restore the content.',
  manageAlertsActionsDeleteError:
    'Something went wrong when trying to delete the alert(s)',
  manageAlertsActionsDeleteSuccess: 'Deleted alert(s)',
  manageBannersActionsDeleteDialogTitle:
    'Are you sure you want to delete the banner(s)?',
  manageBannersActionsDeleteDialogText:
    'It will not be possible to restore the content.',
  manageBannersActionsDeleteError:
    'Something went wrong when trying to delete the banner(s)',
  manageBannersActionsDeleteSuccess: 'Deleted banner(s)',
  manageContentActionsDelete: 'Delete',
  manageContentActionsDeleteDialogText:
    'Deleting these pages will automatically delete all the sub-pages associated with them.',
  manageContentActionsDeleteDialogTitle:
    'Are you sure you want to delete these page(s)?',
  manageContentActionsDeleteError:
    'Something went wrong when trying to delete library page(s)',
  manageContentActionsDeleteSuccess: 'Deleted library page(s)',
  manageContentActionsReassign: 'Reassign',
  manageContentActionsReassignDialogText: 'You can select only 1 employee',
  manageContentActionsReassignDialogTitle: 'Reassign page(s)',
  manageContentActionsReassignError:
    'Something went wrong when trying to reassign library page(s)',
  manageContentActionsReassignSuccess: 'Reassigned library page(s)',
  manageContentActionsReassignUserInputError: 'Select an employee to reassign',
  manageContentActionsRenew: 'Renew',
  manageContentActionsRenewDialogText:
    'Update the dates in the selected page(s) to be valid for two more years.',
  manageContentActionsRenewDialogTitle: "Renew page's dates",
  manageContentActionsRenewError:
    'Something went wrong when trying to renew library page(s)',
  manageContentActionsRenewSuccess: 'Renewed library page(s)',
  manageContentActionsRepublish: 'Republish',
  manageContentActionsRepublishDialogText:
    'Republishing the page(s) will also republish parent page(s).',
  manageContentActionsRepublishDialogTitle:
    'Are you sure you want to republish these page(s)?',
  manageContentActionsRepublishError:
    'Something went wrong when trying to republish library page(s)',
  manageContentActionsRepublishSuccess: 'Republished library page(s)',
  manageContentActionsSelectedMany: '{{count}} items selected',
  manageContentActionsSelectedOne: '{{count}} item selected',
  manageContentActionsUnpublish: 'Unpublish',
  manageContentActionsUnpublishDialogText:
    'Unpublishing these pages will automatically unpublish all the sub-pages associated with them.',
  manageContentActionsUnpublishDialogTitle: 'Unpublish page(s) ',
  manageContentActionsUnpublishError:
    'Something went wrong when trying to unpublish library page(s)',
  manageContentActionsUnpublishSuccess: 'Unpublished library page(s)',
  manageContentBrokenLinks: 'Broken links',
  manageContentComments: 'Comments',
  manageContentContent: 'Content',
  manageContentCreateAlert: 'Create Alert',
  manageContentCreateHeroBanner: 'Create Hero Banner',
  manageContentCreateNewsletterBanner: 'Create Newsletter Banner',
  manageContentDeletionDate: 'Deletion date',
  manageContentDivisions: 'Divisions',
  manageContentDrafts: 'Drafts',
  manageContentEmpty: 'No content to display',
  manageContentEmptyLibraryDrafts: "You don't own any draft pages.",
  manageContentEmptyLibraryPublished: "You don't own any published pages.",
  manageContentEmptyLibraryUnpublished: "You don't own any unpublished pages.",
  manageContentEndDate: 'End date',
  manageContentExpiryDate: 'Expiry date',
  manageContentFeedActionsUnpublishDialogText:
    'Unpublishing these articles removes them from Avenue and makes them unavailable to users.',
  manageContentFeedActionsUnpublishDialogTitle: 'Unpublish article(s)',
  manageContentFeedActionsUnpublishError:
    'Something went wrong when trying to unpublish feed articles',
  manageContentFeedActionsUnpublishSuccess: 'Unpublished articles',
  manageContentFetchError: 'Unable to load content, please try again later',
  manageContentFilterAllContent: 'All content',
  manageContentFilterMyContent: 'My content',
  manageContentHasSubPages: 'Has sub-pages',
  manageContentInformationOwner: 'Owner',
  manageContentLibraryDeletionInfo:
    'The page will be deleted 30 months after it was last updated',
  manageContentLibraryExpiryInfo:
    'The page will be unpublished 24 months after it was last updated',
  manageContentLikes: 'Likes',
  manageContentModifiedDate: 'Modified',
  manageContentOwnerless: 'Ownerless',
  manageContentPublished: 'Published',
  manageContentPublishedDate: 'Published date',
  manageContentPublishTime: 'Publish time',
  manageContentPublishedDateExpiryInfo:
    'The article will be unpublished 24 months after it was published',
  manageContentScheduled: 'Scheduled',
  manageContentStartDate: 'Start date',
  manageContentTitle: 'Title',
  manageContentType: 'Type',
  manageContentTypeHeroBanner: 'Hero',
  manageContentTypeNewsletterBanner: 'Newsletter',
  manageContentUnpublished: 'Unpublished',
  manageContentViews: 'Views',
  mediaInputImage: 'Cover image',
  mediaInputImageInfo:
    'The cover image should be in landscape orientation with a minimum resolution of <strong>1050px x 590px</strong>.',
  mediaInputVideo: 'Embed video',
  mediaInputVideoInfo:
    'You can embed video URLs from <strong>Microsoft Stream</strong>, <strong>YouTube</strong>, <strong>Vimeo</strong> or <strong>Creo</strong>.',
  more: 'More',
  myMoment: '#MyMoment',
  myMomentFetchError:
    'Something went wrong when trying to get #MyMoment page, please try again later.',
  myMomentIframeTitle: 'My moment embedded video',
  myMomentInputTitle: 'Share your moment here',
  myMomentInputPlaceHolder: 'Share a moment',
  myMomentInputSubmit: 'Post Moment',
  myMomentInputSubmitModalTitle: 'Do you want to publish this moment?',
  myMomentInputSubmitModalText:
    'This moment will be visible to other employees',
  myMomentLatest: 'Latest #MyMoment',
  myMomentLoadMore: 'More moments',
  myMomentReadMore: 'All Moments',
  myMomentVideos: 'Moments shared by:',
  newsletterBanner: 'Newsletter Banner',
  newsletterBannerCreateError:
    'Something went wrong when creating the newsletter banner',
  newsletterBannerCreateSuccess: 'Successfully created newsletter banner',
  newsletterBannerDeleteError:
    'Something went wrong when trying to delete the newsletter banner',
  newsletterBannerDeleteSuccess: 'Successfully deleted newsletter banner',
  newsletterBannerDeleteText: 'It will not be possible to restore the content.',
  newsletterBannerDeleteTitle:
    'Are you sure you want to delete this newsletter banner?',
  newsletterBannerEditError:
    'Something went wrong when updating the newsletter banner',
  newsletterBannerEditSuccess: 'Successfully updated newsletter banner',
  newsletterBannerGetError:
    'Something went wrong when fetching newsletter banner data',
  notAvailable: 'N/A',
  notAvailableLong: 'Not available',
  notFound: 'Not found',
  open: 'Open',
  or: 'Or',
  owner: 'Owner',
  owners: 'Owners',
  pageTitleHome: 'Home',
  pageViewFeedToolTipText: 'Total views for this page. Last 24 h not included.',
  pageViewLibraryToolTipText:
    'Views for this page in the last 30 days. Last 24 h not included.',
  paginationRowsPerPage: 'Rows per page',
  paste: 'Paste',
  phoneMobile: 'Mobile Phone',
  phoneOffice: 'Office Phone',
  productOfTheWeek: 'Product of the week',
  productOfTheWeekGallery: 'Gallery',
  productOfTheWeekGalleryTitle: 'Product of the week gallery',
  productOfTheWeekGalleryLinkLabel: 'Previous products',
  profile: 'Profile',
  profileAvatarAlt: 'Employee profile picture',
  profileDetails: 'Profile details',
  profileFetchError:
    'Something went wrong when trying to get your profile data, please try again later',
  profileFetchFormDataError:
    'An error occurred while populating parts of the profile form data',
  profileManageAlerts: 'Alerts',
  profileManageBanners: 'Banners',
  profileManageFeed: 'Feed articles',
  profileManageInsightsDatabase: 'Insights database',
  profileManageLibrary: 'Library pages',
  profileOverview: 'Profile overview',
  profilePreferences: 'Preferences',
  profilePreferencesCountryInfo:
    'Select a division in order to change the country.',
  profilePreferencesDivisionInfo:
    'You have not been assigned to a division, please contact support for assistance.',
  profilePreferencesInfo1:
    'We fetch your profile information from our HR systems.',
  profilePreferencesInfo2:
    'To make sure the content on Avenue matches your profile and personal interest, you are able to update your Avenue profile here.',
  profilePreferencesInfo3:
    'These changes only have effect on Avenue and will not reflect to any other system.',
  profilePreferencesInfo4:
    'Want to look your best? Add or change your profile picture {{here}}.',
  profileSettings: 'Profile',
  profileSubscriptions: 'Subscriptions',
  profileSubscriptionsInsightsDatabase:
    'I would like to receive the Latest Insights Database Articles in my Inbox.',
  profileUpdateSuccess: 'Your profile has been updated',
  profileUpdateError: 'Failed to update profile, please try again later',
  publish: 'Publish',
  published: 'Published',
  readMore: 'Read more',
  readMoreStories: 'All Stories',
  readingTime: 'Reading time less than {{readingTime}} min',
  related: 'Related',
  released: 'Released',
  resources: 'Resources',
  resourcesEmpty: 'No resources available',
  resourcesFetchError: 'Unable to load all resources',
  resourcesTitle: 'Add resources',
  resourcesMaxLinks: 'Maximum {{maxLength}} links',
  resourcesLinkUrlPlaceholder: 'https:',
  retry: 'Retry',
  save: 'Save',
  search: 'Search',
  searchEmployees: 'Search employees',
  searchHitBrandPortal: 'Brand Portal',
  searchHitBrandPortalAssets: 'Assets',
  searchKeywordsInfoText:
    'Here you can enter important keywords that you think people might use to search for your content.',
  searchKeywordsTitle: 'Keywords',
  searchPeople: 'Search people',
  searchSelectorFetchError:
    'Failed to get search suggestions, please try again later.',
  secondaryDepartment: 'Secondary Function',
  secondaryDepartmentPlaceholder: 'Select one more function (optional)',
  select: 'Select',
  send: 'Send',
  segment: 'Segment',
  share: 'Share',
  shareEmailTemplateArticleSubject: 'Recommended read: {{title}}',
  shareEmailTemplateArticleBody:
    'Hi,\n\nI came across this article on Avenue and thought it might be of interest to you. Here is the link to the article: {{link}}\n\nBest regards,\n{{name}}',
  shareEmailTemplateJobSubject: 'Check out this internal position: {{title}}',
  shareEmailTemplateJobBody:
    'Hi,\n\nBelow is a link to a position that was posted in the Avenue Jobs-section that I would like to share with you.\n\n {{link}}\n\nBest regards,\n{{name}}',
  shareMenuCopyLink: 'Copy link',
  shareMenuSendEmail: 'E-mail',
  shareMenuSendEmailConfirmation: 'An email is created for you',
  shortcutsManage: 'Manage',
  shortcutsManageTools: 'Manage pinned tools',
  shortcutsPinnedTools: 'Pinned tools',
  shortcutsPinnedToolsEmpty: `You haven't pinned any tools yet. Click "Manage" to select which tools to pin here.`,
  shortcutsPinnedToolsInfo: 'You are able to pin a maximum of 10 tools.',
  shortcutsPinnedToolsLimit: 'You have reached a maximum of 10 pinned tools.',
  site: 'Site',
  siteFetchError: 'Failed to get sites, please try again later',
  storiesNotAvailable: 'No stories available',
  storiesTitle: 'Stories',
  successful: 'Successful',
  translate: 'Translate',
  updatedLibraryArticlesTitle: 'Updated Pages',
  updatedLibraryArticlesNotAvailable:
    'We have nothing to show here right now. Explore more in the {{Group Library}}.',
  upload: 'Upload',
  videos: 'Videos',
  warning: 'Warning',
  whitelistLinkDialogText1: 'You are about to leave Avenue for:',
  whitelistLinkDialogText2: 'Are you sure?',
  whitelistLinkDialogTitle: 'You are leaving Avenue',
  yes: 'Yes',
};

export { en };
