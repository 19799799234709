import Button from '@mui/material/Button';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Stack, { StackProps } from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { Icon } from 'componentsNew';
import { AccordionWithCheckboxes } from 'pagesInnovation/common';
import { useCallback, useEffect, useRef, useState } from 'react';
import { translations } from 'translations';

import { EMPTY_ACTIVE_FILTER, Filter, FilterIds, FilterName } from '../helpers';

const elementId = 'learning-filter';

type LearningFilterMobileProps = {
  sx?: StackProps['sx'];
  activeFilter: FilterIds;
  availableFilter: Filter;
  onChange: (value: FilterIds) => void;
};

const LearningFilterMobile = ({
  sx,
  availableFilter,
  activeFilter,
  onChange,
}: LearningFilterMobileProps) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [filterInput, setFilterInput] =
    useState<FilterIds>(EMPTY_ACTIVE_FILTER);

  const menuRef = useRef<HTMLDivElement | null>(null);
  const menuButtonRef = useRef<HTMLButtonElement | null>(null);

  const handleCheckboxChange = useCallback(
    (
      name: FilterName,
      item: { name: string; value: string },
      checked: boolean
    ) => {
      setFilterInput((prevFilter) => {
        const newIds = checked
          ? [...prevFilter[name], item.value]
          : prevFilter[name].filter((id) => id !== item.value);
        const newFilter = {
          ...prevFilter,
          [name]: newIds,
        };
        return newFilter;
      });
    },
    []
  );

  const handleSubmit = useCallback(() => {
    onChange(filterInput);
    setIsOpen(false);
  }, [filterInput, onChange]);

  const handleClear = useCallback(() => {
    setFilterInput(EMPTY_ACTIVE_FILTER);
    onChange(EMPTY_ACTIVE_FILTER);
    setIsOpen(false);
  }, [onChange]);

  useEffect(() => {
    setFilterInput(activeFilter);
  }, [activeFilter]);

  return (
    <Stack
      id={elementId}
      sx={(theme) => ({ position: 'relative', gap: theme.spacing('xxs') })}
    >
      <Divider />
      <Button
        variant="text"
        id={`${elementId}-expand`}
        ref={menuButtonRef}
        startIcon={<Icon type="adjustmentsHorizontal" color="brandBase" />}
        onClick={() => setIsOpen((prevIsOpen) => !prevIsOpen)}
        sx={(theme) => ({
          alignSelf: 'flex-start',
          ...(isOpen && {
            '&.MuiButton-text': {
              color: theme.colors.text.actionHover,
              backgroundColor: theme.colors.surface.actionTertiaryHover,
              border: `1px solid ${theme.colors.border.brandBase}`,
            },
          }),
        })}
      >
        {translations.filter}
      </Button>
      <Divider />
      {isOpen && (
        <ClickAwayListener
          onClickAway={(e) => {
            if (
              e.target === menuRef.current ||
              e.target === menuButtonRef.current
            ) {
              return;
            }
            setIsOpen(false);
          }}
        >
          <Stack
            id={`${elementId}-menu`}
            ref={menuRef}
            sx={[
              (theme) => ({
                position: 'absolute',
                top: '100%',
                backgroundColor: theme.colors.surface.primary,
                borderRadius: theme.border.radius.md,
                boxShadow: theme.elevation.sm,
                zIndex: theme.zIndex.tooltip,
                padding: theme.spacing('sm'),
                gap: theme.spacing('xs'),
                maxHeight: '70vh',
                overflowY: 'scroll',
              }),
              ...(Array.isArray(sx) ? sx : [sx]),
            ]}
          >
            <Stack
              sx={{
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
                width: '100%',
              }}
            >
              <Typography variant="h4" component="h2">
                {translations.learningFilterTitle}
              </Typography>
              <IconButton
                size="small"
                aria-label={translations.close}
                onClick={() => setIsOpen(false)}
              >
                <Icon type="xMark" color="secondary" />
              </IconButton>
            </Stack>
            <Divider />
            {availableFilter.types.length > 0 && (
              <>
                {' '}
                <AccordionWithCheckboxes
                  elementId={`${elementId}-${FilterName.Types}`}
                  title={translations.innovationTypes}
                  value={filterInput.types}
                  items={availableFilter.types.map((option) => ({
                    name: option.name,
                    value: option.id,
                  }))}
                  defaultExpanded
                  disableExpandAndCollapse
                  onChange={(item, checked) =>
                    handleCheckboxChange(FilterName.Types, item, checked)
                  }
                />
                <Divider />
              </>
            )}
            {availableFilter.focusAreas.length > 0 && (
              <AccordionWithCheckboxes
                elementId={`${elementId}-${FilterName.FocusAreas}`}
                title={translations.innovationFocusAreas}
                value={filterInput.focusAreas}
                items={availableFilter.focusAreas.map((option) => ({
                  name: option.name,
                  value: option.id,
                }))}
                defaultExpanded
                disableExpandAndCollapse
                onChange={(item, checked) =>
                  handleCheckboxChange(FilterName.FocusAreas, item, checked)
                }
              />
            )}
            <Stack
              sx={(theme) => ({
                flexDirection: 'row',
                justifyContent: 'end',
                gap: theme.spacing('xs'),
              })}
            >
              <Button variant="outlined" onClick={handleClear}>
                {translations.clear}
              </Button>
              <Button variant="contained" onClick={handleSubmit}>
                {translations.apply}
              </Button>
            </Stack>
          </Stack>
        </ClickAwayListener>
      )}
    </Stack>
  );
};
export { LearningFilterMobile };
