import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import { useTheme } from '@mui/material/styles';
import Grid from '@mui/material/Unstable_Grid2';
import * as cmsInnovationApi from 'api/cms-innovation';
import { InnovationTrainingArticle } from 'api/cms-innovation/types';
import headerBackground from 'assets/images/innovation-article-header-background-learning.png';
import {
  Chip,
  EmbedVideo,
  FetchError,
  HtmlParser,
  Image,
  ProfileMiniList,
} from 'componentsNew';
import { AvenueRouteEnum, InnovationRouteEnum } from 'enums';
import { Page } from 'layout';
import {
  ArticleHeader,
  ArticleResources,
  ArticleSkeleton,
  RelatedArticles,
  TimestampsAndNote,
} from 'pagesInnovation/common';
import { useEffect, useMemo, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { translations } from 'translations';
import {
  GAonInnovationArticleOwnerClick,
  GAonInnovationArticleRelatedClick,
  GAonInnovationArticleResourceDocumentClick,
  GAonInnovationArticleResourceLinkClick,
} from 'utils/analytics';

const elementId = 'training-article';

const TrainingArticle = () => {
  const [article, setArticle] = useState<InnovationTrainingArticle | null>(
    null
  );
  const [isError, setIsError] = useState<boolean>(false);

  const theme = useTheme();
  const history = useHistory();
  const params = useParams<{ id: string }>();

  const documentTitle = useMemo(
    () =>
      article
        ? [
            translations.innovationDocumentTitle,
            translations.learning,
            article.title,
          ]
        : null,
    [article]
  );

  const documentBreadcrumbs = useMemo(
    () =>
      article
        ? [
            {
              text: translations.learning,
              to: InnovationRouteEnum.Learning,
            },
            { text: article.title },
          ]
        : null,
    [article]
  );

  useEffect(() => {
    if (params.id === article?.id || isError) {
      return;
    }
    const fetchArticle = async () => {
      try {
        setIsError(false);
        const data = await cmsInnovationApi.getTrainingArticle(params.id);
        setArticle(data);
      } catch (e: any) {
        if (e['status'] === 404) {
          history.push(AvenueRouteEnum.NotFound);
          return;
        }
        setIsError(true);
      }
    };
    fetchArticle();
  }, [article, history, isError, params.id]);

  if (isError) {
    return (
      <Page title={documentTitle} gridContainer>
        <FetchError />
      </Page>
    );
  }

  if (!article) {
    return (
      <Page title={documentTitle} gridContainer>
        <ArticleSkeleton />
      </Page>
    );
  }

  return (
    <Page title={documentTitle} breadcrumbs={documentBreadcrumbs} gridContainer>
      <Grid xs={12}>
        <ArticleHeader
          title={article.title}
          preamble={article.preamble}
          focusAreas={article.focusAreas}
          accentColor={theme.palette.secondary2[900]}
          background={headerBackground}
          elements={{
            leftColumnTop: (
              <Chip
                variant="outlined"
                size="small"
                label={translations.innovationTypeTraining}
                sx={(theme) => ({
                  '&.MuiChip-outlined.MuiChip-outlinedDefault': {
                    fontWeight: theme.typography.fontWeightBold,
                    borderColor: theme.palette.secondary2[900],
                    color: theme.palette.secondary2[900],
                    backgroundColor: 'unset',
                  },
                })}
              />
            ),
            rightColumn:
              article.owners?.internalPersons &&
              article.owners.internalPersons.length ? (
                <ProfileMiniList
                  elementId={`${elementId}-owners`}
                  title={
                    article.owners.internalPersons.length > 1
                      ? `${translations.owners}:`
                      : `${translations.owner}:`
                  }
                  users={article.owners.internalPersons.map((owner) => ({
                    id: owner.oid,
                    name: owner.name,
                  }))}
                  sx={(theme) => ({
                    paddingLeft: { md: theme.spacing('md') },
                    borderLeft: {
                      md: `1px solid ${theme.colors.border.surfaceSecondary}`,
                    },
                  })}
                  onClick={(user) => GAonInnovationArticleOwnerClick(user.name)}
                />
              ) : undefined,
          }}
        />
      </Grid>
      <Grid xs={12} md={8}>
        <Stack sx={(theme) => ({ gap: theme.spacing('sm') })}>
          {article.heroImageUrl && !article.heroVideoUrl && (
            <Image
              alt={article.heroAltText}
              title={article.heroAltText}
              src={article.heroImageUrl}
              originalHeight={article.heroImageHeight}
              originalWidth={article.heroImageWidth}
              aspectRatio={2}
              sx={(theme) => ({ borderRadius: theme.border.radius.lg })}
            />
          )}
          {article.heroVideoUrl && (
            <EmbedVideo
              title={article.heroAltText}
              src={article.heroVideoUrl}
              aspectRatio={2}
              sx={(theme) => ({ borderRadius: theme.border.radius.lg })}
            />
          )}
          <TimestampsAndNote
            note={article.note}
            created={article.created}
            lastModified={article.lastModified}
          />
          <Divider
            sx={(theme) => ({
              margin: `${theme.spacing('xxs')} 0`,
              borderColor: theme.colors.border.surfacePrimary,
            })}
          />
          {article.body && <HtmlParser html={article.body} />}
        </Stack>
      </Grid>
      <Grid xs={12} md={4}>
        <Stack sx={(theme) => ({ gap: theme.spacing('lg') })}>
          <ArticleResources
            elementId={`${elementId}-resources`}
            links={article.resources?.links || []}
            documentsFolderId={article.resources?.sharepointFolderName}
            onLinkClick={(link) =>
              GAonInnovationArticleResourceLinkClick(link.title)
            }
            onDocumentClick={(document) =>
              GAonInnovationArticleResourceDocumentClick(document.name)
            }
          />
          <RelatedArticles
            elementId={`${elementId}-related-articles`}
            article={article}
            onClick={(article) =>
              GAonInnovationArticleRelatedClick(article.title)
            }
          />
        </Stack>
      </Grid>
    </Page>
  );
};

export { TrainingArticle };
