import { ButtonProps } from '@mui/material/Button';
import React, { useMemo, useState } from 'react';

import { EmbedVideoModal, EmbedVideoValue } from './EmbedVideoModal';

type EmbedVideoAddProps = {
  elementId: string;
  triggerElement: React.ReactElement<ButtonProps>;
  onSubmit: (value: EmbedVideoValue) => void;
};

const EmbedVideoAdd = ({
  elementId,
  triggerElement,
  onSubmit,
}: EmbedVideoAddProps) => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const trigger = useMemo(() => {
    return React.cloneElement(triggerElement, {
      id: `${elementId}-button`,
      onClick: () => setIsModalOpen(true),
    });
  }, [elementId, triggerElement]);

  return (
    <>
      {trigger}

      {isModalOpen && (
        <EmbedVideoModal
          elementId={`${elementId}-modal`}
          defaultValue={{ url: '' }}
          onSubmit={onSubmit}
          onClose={() => setIsModalOpen(false)}
        />
      )}
    </>
  );
};

export { EmbedVideoAdd };
