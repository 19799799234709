import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import { Theme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { SxProps } from '@mui/system/styleFunctionSx';
import { JobListingItem, JobSortEnum } from 'api/jobs/types';
import { AlertBar } from 'componentsNew';
import { useMemo } from 'react';
import { translations } from 'translations';

import { JobSort } from '../JobSort/JobSort';
import { JobListError } from './JobListError';
import { JobListItem } from './JobListItem';
import { JobListSkeleton } from './JobListSkeleton';
import { JobListTotal } from './JobListTotal';

const elementId = 'job-list';

type JobListProps = {
  sx?: SxProps<Theme>;
  isLoading?: boolean;
  isError?: boolean;
  jobList: {
    items: JobListingItem[];
    total: number;
  } | null;
  activeItemId?: string;
  pagination: {
    page: number;
    rowsPerPage: number;
  };
  activeSort: JobSortEnum;
  onItemClick: (item: JobListingItem) => void;
  onPageChange: (page: number) => void;
  onSortChange: (sort: JobSortEnum) => void;
};

const JobList = ({
  sx,
  isLoading,
  isError,
  jobList,
  activeItemId,
  pagination,
  activeSort,
  onItemClick,
  onPageChange,
  onSortChange,
}: JobListProps) => {
  const pageCount = useMemo(() => {
    if (!jobList) {
      return 0;
    }
    return jobList.total % pagination.rowsPerPage > 0
      ? Math.trunc(jobList.total / pagination.rowsPerPage) + 1
      : jobList.total / pagination.rowsPerPage;
  }, [jobList, pagination.rowsPerPage]);

  const isPageOutOfBound = useMemo(() => {
    return pagination.page > pageCount && jobList && !jobList.items.length;
  }, [jobList, pageCount, pagination.page]);

  const paginationElement = useMemo(() => {
    if (
      !jobList ||
      (jobList.total <= pagination.rowsPerPage && !isPageOutOfBound)
    ) {
      return null;
    }

    return (
      <Pagination
        variant="outlined"
        sx={(theme) => ({ margin: `${theme.spacing('xs')} auto` })}
        count={pageCount}
        page={pagination.page}
        disabled={isLoading}
        siblingCount={0}
        hidePrevButton={pagination.page === 1}
        hideNextButton={pagination.page === pageCount}
        onChange={(_e, page) => {
          if (isPageOutOfBound) {
            onPageChange(pageCount);
            return;
          }
          onPageChange(page);
        }}
      />
    );
  }, [
    isLoading,
    isPageOutOfBound,
    jobList,
    onPageChange,
    pageCount,
    pagination.page,
    pagination.rowsPerPage,
  ]);

  const headerElement = useMemo(() => {
    if (isError || !jobList || (isLoading && jobList && jobList.total <= 0)) {
      return null;
    }

    if (jobList.total <= 0) {
      return (
        <AlertBar
          open
          fullWidth
          type="information"
          title={translations.jobsTotalZeroTitle}
          text={translations.jobsTotalZeroText}
          sx={{ marginTop: '2rem' }}
        />
      );
    }

    return (
      <>
        <Stack
          sx={[
            (theme) => ({
              flexDirection: 'row',
              flexWrap: 'wrap',
              justifyContent: 'space-between',
              alignItems: 'center',
              gap: theme.spacing('xs'),
            }),
          ]}
        >
          <JobListTotal total={jobList.total} sx={{ flexShrink: 0 }} />
          <JobSort onChange={onSortChange} activeSort={activeSort} />
        </Stack>
        <Divider />
        {isPageOutOfBound && (
          <AlertBar
            open
            fullWidth
            type="information"
            title={translations.jobsEmptyDueToPaginationTitle}
            text={translations.jobsEmptyDueToPaginationText}
            sx={{ marginTop: '2rem' }}
          />
        )}
      </>
    );
  }, [isError, isLoading, isPageOutOfBound, jobList, activeSort, onSortChange]);

  const listElement = useMemo(() => {
    if (isError) {
      return <JobListError />;
    }
    if (isLoading || !jobList) {
      return <JobListSkeleton />;
    }
    return (
      <>
        <Typography variant="srOnly" component="h2">
          {translations.jobOpeningsTitle}
        </Typography>
        <List
          sx={(theme) => ({
            display: 'flex',
            flexDirection: 'column',
            gap: theme.spacing('xs'),
            marginBottom: theme.spacing('xs'),
          })}
        >
          {jobList.items.map((item, index) => (
            <JobListItem
              key={`${elementId}-item-${index}`}
              elementId={`${elementId}-item-${index}`}
              isActive={`${item.id}` === activeItemId}
              item={item}
              onClick={() => onItemClick(item)}
            />
          ))}
        </List>
      </>
    );
  }, [activeItemId, isError, isLoading, jobList, onItemClick]);

  return (
    <Stack
      id={elementId}
      sx={[
        (theme) => ({ width: '100%', gap: theme.spacing('xxs') }),
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
    >
      {headerElement}
      {!isPageOutOfBound && paginationElement}
      {listElement}
      {paginationElement}
    </Stack>
  );
};

export { JobList };
