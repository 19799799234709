import Stack from '@mui/material/Stack';
import { EmbedVideo } from 'componentsNew/Embed/EmbedVideo';
import { useMemo, useState } from 'react';
import { translations } from 'translations';

import { MediaActionButtons } from '../MediaActionButtons';
import { EmbedVideoModal, EmbedVideoValue } from './EmbedVideoModal';

type EmbedVideoEditProps = {
  elementId: string;
  value: EmbedVideoValue;
  onChange: (value: EmbedVideoValue) => void;
  onDelete?: () => void;
};

const EmbedVideoEdit = ({
  elementId,
  value,
  onChange,
  onDelete,
}: EmbedVideoEditProps) => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [isPreviewLoaded, setIsPreviewLoaded] = useState<boolean>(false);

  const preview = useMemo(() => {
    return (
      <EmbedVideo
        src={value.url}
        title={translations.mediaInputVideo}
        sx={(theme) => ({ borderRadius: theme.border.radius.lg })}
        onLoad={() => setIsPreviewLoaded(true)}
      />
    );
  }, [value.url]);

  return (
    <>
      {isModalOpen && (
        <EmbedVideoModal
          elementId={`${elementId}-modal`}
          defaultValue={{ url: value.url }}
          onSubmit={(newValue) => {
            onChange(newValue);
            setIsModalOpen(false);
          }}
          onClose={() => setIsModalOpen(false)}
        />
      )}
      <Stack sx={() => ({ position: 'relative' })}>
        {preview}
        {isPreviewLoaded && (
          <MediaActionButtons
            onChange={() => setIsModalOpen(true)}
            onDelete={onDelete}
          />
        )}
      </Stack>
    </>
  );
};
export { EmbedVideoEdit };
