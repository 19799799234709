import Box from '@mui/material/Box';
import React from 'react';

type BlockQuoteProps = {
  children: React.ReactNode;
};

const BlockQuote = ({ children }: BlockQuoteProps) => {
  return (
    <Box
      component="blockquote"
      sx={(theme) => ({
        margin: 0,
        marginBottom: theme.spacing('md'),
        padding: theme.spacing('sm'),
        fontSize: theme.typography.h4.fontSize,
        backgroundColor: `${theme.colors.surface.informative}`,
        borderLeft: `${theme.border.thickness[4]} solid ${theme.colors.icon.brandBase}`,
      })}
    >
      {children}
    </Box>
  );
};

export { BlockQuote };
