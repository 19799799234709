import Stack, { StackProps } from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { InnovationSimpleFocusArea } from 'api/cms-innovation/types';
import { Chip } from 'componentsNew';
import { useBreakpoints } from 'hooks';

type ArticleHeaderProps = {
  sx?: StackProps['sx'];
  title: string;
  preamble: string;
  focusAreas: InnovationSimpleFocusArea[];
  elements?: {
    leftColumnTop?: React.ReactNode;
    rightColumn?: React.ReactNode;
  };
  accentColor?: string;
  background?: string;
};

const ArticleHeader = ({
  sx,
  title,
  preamble,
  focusAreas,
  elements,
  accentColor,
  background,
}: ArticleHeaderProps) => {
  const { isMobile } = useBreakpoints();

  return (
    <Stack
      sx={[
        (theme) => ({
          alignItems: isMobile ? 'baseline' : 'center',
          flexDirection: isMobile ? 'column' : 'row',
          gap: theme.spacing('md'),
          borderRadius: theme.border.radius.xxl,
          background: `linear-gradient(rgba(255,255,255,.5), rgba(255,255,255,.5)), url(${background})`,
          wordBreak: 'break-word',
          padding: {
            xs: `${theme.spacing('md')}`,
            md: `${theme.spacing('lg')} ${theme.spacing('xl')}`,
          },
        }),
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
    >
      <Stack
        sx={(theme) => ({
          alignItems: 'baseline',
          gap: theme.spacing('sm'),
          maxWidth: '100%',
          width: '45rem',
        })}
      >
        {elements?.leftColumnTop}
        <Typography
          variant={isMobile ? 'h2' : 'display4'}
          component="h1"
          sx={(theme) => ({
            color: accentColor,
            fontWeight: theme.typography.fontWeightBold,
            textShadow: theme.elevation.sm,
          })}
        >
          {title}
        </Typography>
        <Typography
          variant={isMobile ? 'body1' : 'h4'}
          component="p"
          sx={() => ({
            color: accentColor,
            fontWeight: 'unset',
          })}
        >
          {preamble}
        </Typography>
        {focusAreas.length > 0 && (
          <Stack
            sx={(theme) => ({
              flexDirection: 'row',
              gap: theme.spacing('xxs'),
              flexWrap: 'wrap',
            })}
          >
            {focusAreas.map((focusArea, index) => (
              <Chip
                key={`focusAreas-${index}-${focusArea.id}`}
                size="small"
                color="default"
                variant="outlined"
                label={focusArea.title}
              />
            ))}
          </Stack>
        )}
      </Stack>
      {elements?.rightColumn}
    </Stack>
  );
};

export { ArticleHeader };
