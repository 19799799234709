import { InnovationLearningListingItemTypeEnum } from 'api/find/types';
import { InnovationRouteEnum } from 'enums';
import { translations } from 'translations';

export const INNOVATION_LISTING_ITEM_TYPE_TO_LABEL: Record<
  InnovationLearningListingItemTypeEnum,
  string
> = {
  [InnovationLearningListingItemTypeEnum.CaseStudy]:
    translations.innovationTypeCaseStudy,
  [InnovationLearningListingItemTypeEnum.Podcast]:
    translations.innovationTypePodcast,
  [InnovationLearningListingItemTypeEnum.Training]:
    translations.innovationTypeTraining,
  [InnovationLearningListingItemTypeEnum.Webinar]:
    translations.innovationTypeWebinar,
};

export const getInnovationListingItemLinkUrl = (
  id: string,
  type: InnovationLearningListingItemTypeEnum
) => {
  switch (type) {
    case InnovationLearningListingItemTypeEnum.CaseStudy:
      return `${InnovationRouteEnum.CaseStudyArticle}/${id}`;
    case InnovationLearningListingItemTypeEnum.Podcast:
      return `${InnovationRouteEnum.PodcastArticle}/${id}`;
    case InnovationLearningListingItemTypeEnum.Training:
      return `${InnovationRouteEnum.TrainingArticle}/${id}`;
    case InnovationLearningListingItemTypeEnum.Webinar:
      return `${InnovationRouteEnum.WebinarArticle}/${id}`;
  }
};
