import { InnovationLearningItemsRequestParams } from './types';

export const getInnovationLearningItemsQueryString = (
  params: InnovationLearningItemsRequestParams
) => {
  const { page, limit, search, filter } = params;

  let str = '';
  if (limit !== undefined) {
    str = str.concat(`?page[limit]=${limit}`);
  }
  if (page !== undefined) {
    str = str.concat(`&page[offset]=${page - 1}`);
  }
  if (filter?.types?.length) {
    const typesStr = JSON.stringify(filter.types);
    str = str.concat(`&filter[types]=${typesStr}`);
  }
  if (filter?.focusAreas?.length) {
    const focusAreasStr = JSON.stringify(filter.focusAreas);
    str = str.concat(`&filter[focusarea.ids]=${focusAreasStr}`);
  }
  if (search?.length) {
    str = str.concat(`&filter[query]=${search}`);
  }
  return str;
};
