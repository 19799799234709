import { ButtonProps } from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import { AlertDialog } from 'componentsNew/AlertDialog/AlertDialog';
import React, { useCallback, useMemo, useState } from 'react';
import { translations } from 'translations';
import * as textUtils from 'utils/misc/text';

import * as helpers from './helpers';
import { BlobImage } from './helpers';
import { ImageDetailsModal, ImageDetailsValue } from './ImageDetailsModal';

export const MIME_TYPES = [
  'image/avif',
  'image/gif',
  'image/jpeg',
  'image/png',
];

type ImageAddProps = {
  elementId: string;
  triggerElement: React.ReactElement<ButtonProps>;
  aspectRatio?: number;
  minWidth?: number;
  minHeight?: number;
  maxSizeBytes?: number;
  onSubmit: (value: ImageDetailsValue) => void;
};

const ImageAdd = ({
  elementId,
  triggerElement,
  aspectRatio,
  minWidth: minWidthProp,
  minHeight: minHeightProp,
  maxSizeBytes,
  onSubmit,
}: ImageAddProps) => {
  const [blobImage, setBlobImage] = useState<BlobImage | null>(null);

  const [errorDialog, setErrorDialog] = useState<{
    error: string;
    open: boolean;
  }>({ error: '', open: false });

  const { minWidth, minHeight } = useMemo(
    () =>
      helpers.getValidMinDimensions({
        minWidth: minWidthProp,
        minHeight: minHeightProp,
        aspectRatio,
      }),
    [aspectRatio, minHeightProp, minWidthProp]
  );

  const closeErrorDialog = useCallback(() => {
    setErrorDialog((prevErrorDialog) => ({
      ...prevErrorDialog,
      open: false,
    }));
  }, []);

  const readAndValidateFile = useCallback(
    (file: File) => {
      if (!MIME_TYPES.includes(file.type)) {
        setErrorDialog({
          open: true,
          error: translations.imageInputInvalidFileType,
        });
        return;
      }
      if (maxSizeBytes && file.size > maxSizeBytes) {
        setErrorDialog({
          open: true,
          error: textUtils.replaceTranslationAliases(
            translations.imageInputInvalidFileSize,
            { size: maxSizeBytes / 1024 / 1024 }
          ),
        });
        return;
      }

      const reader = new FileReader();

      reader.onload = (event) => {
        const image = new Image();

        if (event.target) {
          image.src = event.target.result as string;
        }
        image.onload = () => {
          if (
            image.naturalWidth < minWidth ||
            image.naturalHeight < minHeight
          ) {
            setErrorDialog({
              open: true,
              error: textUtils.replaceTranslationAliases(
                translations.imageInputInvalidDimensions,
                { minWidth, minHeight }
              ),
            });
            return;
          }
          setBlobImage({
            type: 'blob',
            src: image.src,
            width: image.naturalWidth,
            height: image.naturalHeight,
            blob: helpers.base64ToBlob(image.src),
          });
        };
        image.onerror = () => {
          setErrorDialog({
            open: true,
            error: translations.imageInputUploadError,
          });
        };
      };

      reader.readAsDataURL(file);
    },
    [maxSizeBytes, minHeight, minWidth]
  );

  const handleFileChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (!e.target.files) {
        return;
      }
      const file = e.target.files.item(0);
      e.target.value = '';
      if (!file) {
        return;
      }
      readAndValidateFile(file);
    },
    [readAndValidateFile]
  );

  const fileInput = useMemo(() => {
    return React.cloneElement(
      triggerElement,
      {
        component: 'label',
        role: undefined,
        tabIndex: 0,
      },
      ...[
        triggerElement.props.children,
        <FileInput
          id={`${elementId}-file-input`}
          type="file"
          accept={MIME_TYPES.join(', ')}
          onChange={handleFileChange}
        />,
      ]
    );
  }, [elementId, handleFileChange, triggerElement]);

  return (
    <>
      {fileInput}
      <AlertDialog
        id={`${elementId}-error`}
        open={errorDialog.open}
        type="warning"
        size="small"
        title={translations.imageInputInvalid}
        paragraphs={[errorDialog.error]}
        onClose={closeErrorDialog}
        secondaryButton={{
          text: translations.cancel,
          onClick: closeErrorDialog,
        }}
      />
      {blobImage && (
        <ImageDetailsModal
          elementId={`${elementId}-modal`}
          defaultValue={{ ...blobImage, altText: '' }}
          minWidth={minWidth}
          minHeight={minHeight}
          aspectRatio={aspectRatio}
          maxSizeBytes={maxSizeBytes}
          onSubmit={(value) => {
            if (value.type !== 'blob') {
              setErrorDialog({
                open: true,
                error: translations.imageInputUploadError,
              });
              return;
            }
            onSubmit(value);
            setBlobImage(null);
          }}
          onClose={() => {
            setBlobImage(null);
            closeErrorDialog();
          }}
        />
      )}
    </>
  );
};

const FileInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

export { ImageAdd };
