import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { CreatedBy, EditedBy } from 'componentsNew';

type Timestamp = {
  by: {
    name: string;
    oid: string;
  };
  date: string;
};

type TimestampAndNoteProps = {
  elementId?: string;
  note?: string;
  created: Timestamp;
  lastModified: Timestamp;
};

const TimestampsAndNote = ({
  elementId,
  note,
  created,
  lastModified,
}: TimestampAndNoteProps) => {
  return (
    <Stack
      id={elementId}
      sx={(theme) => ({
        flexDirection: 'row',
        alignItems: 'center',
        flexWrap: 'wrap',
        rowGap: theme.spacing('xxs'),
      })}
      divider={
        <Divider
          orientation="vertical"
          sx={(theme) => ({
            margin: `auto ${theme.spacing('xxs')}`,
            height: theme.typography.body1.fontSize,
            borderColor: theme.colors.border.surfaceSecondary,
          })}
        />
      }
    >
      <CreatedBy date={created.date} />
      <EditedBy date={lastModified.date} />
      {note && (
        <Typography
          variant="body2"
          sx={(theme) => ({ color: theme.colors.text.warning })}
        >
          {note}
        </Typography>
      )}
    </Stack>
  );
};

export { TimestampsAndNote };
