import { InnovationLearningListingItemTypeEnum } from 'api/find/types';
import { INNOVATION_LISTING_ITEM_TYPE_TO_LABEL } from 'pagesInnovation/common/helpers';

export enum FilterName {
  Types = 'types',
  FocusAreas = 'focusAreas',
}

export type FilterOption = { id: string; name: string };
export type Filter = Record<FilterName, FilterOption[]>;
export type FilterIds = Record<FilterName, string[]>;

export const ALL_FILTER_NAMES = Object.values(FilterName) as FilterName[];
export const PAGINATION_LIMITS = [15, 25, 50, 100];
export const PAGINATION_DEFAULT_LIMIT = PAGINATION_LIMITS[0];
export const PAGINATION_DEFAULT_PAGE = 1;

export const EMPTY_ACTIVE_FILTER: FilterIds = {
  types: [],
  focusAreas: [],
};

const FILTER_TYPE_OPTIONS: FilterOption[] = [
  {
    id: InnovationLearningListingItemTypeEnum.CaseStudy,
    name: INNOVATION_LISTING_ITEM_TYPE_TO_LABEL[
      InnovationLearningListingItemTypeEnum.CaseStudy
    ],
  },
  {
    id: InnovationLearningListingItemTypeEnum.Podcast,
    name: INNOVATION_LISTING_ITEM_TYPE_TO_LABEL[
      InnovationLearningListingItemTypeEnum.Podcast
    ],
  },
  {
    id: InnovationLearningListingItemTypeEnum.Training,
    name: INNOVATION_LISTING_ITEM_TYPE_TO_LABEL[
      InnovationLearningListingItemTypeEnum.Training
    ],
  },
  {
    id: InnovationLearningListingItemTypeEnum.Webinar,
    name: INNOVATION_LISTING_ITEM_TYPE_TO_LABEL[
      InnovationLearningListingItemTypeEnum.Webinar
    ],
  },
];

const getFilterLabelsAsStr = (filter: FilterIds, availableFilter: Filter) => {
  const filterLabels: string[] = [];
  ALL_FILTER_NAMES.forEach((name) => {
    const ids = filter[name];
    ids.forEach((id) => {
      const filterOption = availableFilter[name].find(
        (option) => option.id === id
      );
      if (filterOption) {
        filterLabels.push(filterOption.name);
      }
    });
  });
  return filterLabels.join(', ');
};

const parseFilter = (filterStr: string) => {
  try {
    if (!filterStr) {
      return EMPTY_ACTIVE_FILTER;
    }
    const filterObject = JSON.parse(filterStr);
    if (typeof filterObject !== 'object') {
      return EMPTY_ACTIVE_FILTER;
    }
    const filter = { ...EMPTY_ACTIVE_FILTER };
    ALL_FILTER_NAMES.forEach((name) => {
      const filterValue = filterObject[name];
      if (filterValue) {
        filter[name] = filterValue;
      }
    });
    return filter;
  } catch {
    return EMPTY_ACTIVE_FILTER;
  }
};

const parseSearch = (searchString: string) => {
  return searchString ? (JSON.parse(searchString) as string) : '';
};

const stringifyFilter = (filter: FilterIds) => {
  const cleanedFilter: Partial<FilterIds> = {};
  ALL_FILTER_NAMES.forEach((name) => {
    const ids = filter[name];
    if (ids.length) {
      cleanedFilter[name] = ids;
    }
  });
  const isEmpty = !Object.keys(cleanedFilter).length;
  return isEmpty ? '' : JSON.stringify(cleanedFilter);
};

const stringifySearch = (search: string) => {
  return search ? JSON.stringify(search) : '';
};

export {
  FILTER_TYPE_OPTIONS,
  getFilterLabelsAsStr,
  parseFilter,
  parseSearch,
  stringifyFilter,
  stringifySearch,
};
