import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import * as cmsInnovationApi from 'api/cms-innovation';
import * as findApi from 'api/find';
import {
  InnovationLearningItemsRequestParams,
  InnovationLearningListingItem,
} from 'api/find/types';
import { TablePagination } from 'componentsNew';
import { useSnackbar } from 'context/Snackbar';
import { useBreakpoints, usePrevious } from 'hooks';
import { Page, PageTitle } from 'layout';
import { debounce } from 'lodash';
import { ArticleList } from 'pagesInnovation/common';
import { ArticleListSkeleton } from 'pagesInnovation/common/ArticleList/ArticleListSkeleton';
import { useEffect, useMemo, useState } from 'react';
import { useCallback } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { translations } from 'translations';
import {
  GAonInnovationFilterSubmit,
  GAonInnovationSearchSubmit,
  GAonShowMoreClick,
} from 'utils/analytics';

import * as helpers from './helpers';
import {
  Filter,
  FILTER_TYPE_OPTIONS,
  FilterIds,
  PAGINATION_DEFAULT_LIMIT,
  PAGINATION_DEFAULT_PAGE,
  PAGINATION_LIMITS,
} from './helpers';
import { LearningEmpty } from './LearningEmpty';
import { LearningFilter } from './LearningFilter/LearningFilter';
import { LearningFilterSkeleton } from './LearningFilter/LearningFilterSkeleton';
import { LearningSearch } from './LearningSearch';

const Learning = () => {
  const [availableFilter, setAvailableFilter] = useState<Filter | null>(null);

  const [list, setList] = useState<{
    items: InnovationLearningListingItem[];
    total: number;
  } | null>(null);

  const [isListError, setIsListError] = useState<boolean>(false);
  const [isListLoading, setIsListLoading] = useState<boolean>(false);
  const [isAvailableFilterLoading, setIsAvailableFilterLoading] =
    useState<boolean>(false);

  const history = useHistory();
  const location = useLocation();
  const { isMobile } = useBreakpoints();
  const { showSnackbar } = useSnackbar();

  const activeParams = useMemo(() => {
    const urlSearchParams = new URLSearchParams(location.search);

    const pageStr = urlSearchParams.get('page') || '';
    const page = pageStr ? Number(pageStr) : 1;

    const limitStr = urlSearchParams.get('limit') || '';
    const limit = limitStr ? Number(limitStr) : PAGINATION_DEFAULT_LIMIT;

    const filterStr = urlSearchParams.get('filter') || '';
    const filter = helpers.parseFilter(filterStr);

    const searchStr = urlSearchParams.get('search') || '';
    const search = helpers.parseSearch(searchStr);

    return { page, limit, search, filter, filterStr };
  }, [location.search]);

  const prevActiveParams = usePrevious(activeParams);

  const documentTitle = useMemo(
    () => [translations.innovationDocumentTitle, translations.learning],
    []
  );

  const pageCount = useMemo(() => {
    if (!list) {
      return 0;
    }
    return list.total % activeParams.limit > 0
      ? Math.trunc(list.total / activeParams.limit) + 1
      : list.total / activeParams.limit;
  }, [activeParams.limit, list]);

  const isPageOutOfBound = useMemo(() => {
    return (
      activeParams.page > 1 &&
      activeParams.page > pageCount &&
      list !== null &&
      !list.items.length
    );
  }, [activeParams.page, list, pageCount]);

  const fetchAvailableFilter = useCallback(async () => {
    const availableFilter: Filter = {
      types: FILTER_TYPE_OPTIONS,
      focusAreas: [],
    };
    try {
      setIsAvailableFilterLoading(true);
      const data = await cmsInnovationApi.getSimpleFocusAreas();
      availableFilter.focusAreas = data.map((focusArea) => ({
        id: focusArea.id,
        name: focusArea.title,
      }));
    } catch {
      showSnackbar({
        type: 'error',
        text: translations.innovationFilterFetchError,
      });
    } finally {
      setIsAvailableFilterLoading(false);
      return availableFilter;
    }
  }, [showSnackbar]);

  const fetchList = useCallback(
    async (params: InnovationLearningItemsRequestParams) => {
      const list: {
        items: InnovationLearningListingItem[];
        total: number;
      } = {
        items: [],
        total: 0,
      };
      try {
        setIsListLoading(true);
        setIsListError(false);
        const query = findApi.getInnovationLearningItemsQueryString(params);
        const response = await findApi.getInnovationLearningItems(query);
        if (!response?.data?.data) {
          throw Error();
        }
        list.total = response.data.data.total as number;
        list.items = response.data.data
          .items as InnovationLearningListingItem[];
        return list;
      } catch {
        setIsListError(true);
        showSnackbar({
          type: 'error',
          text: translations.innovationListFetchError,
        });
      } finally {
        setIsListLoading(false);
        return list;
      }
    },
    [showSnackbar]
  );

  const fetchListWithDebounce = useMemo(() => {
    return debounce(
      async (
        params: InnovationLearningItemsRequestParams,
        callback: (newList: {
          items: InnovationLearningListingItem[];
          total: number;
        }) => void
      ) => {
        const newList = await fetchList(params);
        callback(newList);
      },
      500,
      { leading: true, trailing: true }
    );
  }, [fetchList]);

  const handlePageChange = useCallback(
    (value: number) => {
      const page = isPageOutOfBound ? pageCount : value;
      const urlSearchParams = new URLSearchParams(location.search);
      urlSearchParams.set('page', `${page}`);
      history.push({ search: urlSearchParams.toString() });
      GAonShowMoreClick('Innovation');
    },
    [history, isPageOutOfBound, location.search, pageCount]
  );

  const handleLimitChange = useCallback(
    (value: number) => {
      const urlSearchParams = new URLSearchParams(location.search);
      urlSearchParams.set('limit', `${value}`);
      urlSearchParams.set('page', `${PAGINATION_DEFAULT_PAGE}`);
      history.push({ search: urlSearchParams.toString() });
      GAonShowMoreClick('Innovation');
    },
    [history, location.search]
  );

  const handleFilterChange = useCallback(
    (value: FilterIds) => {
      const stringified = helpers.stringifyFilter(value);
      const urlSearchParams = new URLSearchParams(location.search);
      if (stringified) {
        urlSearchParams.set('filter', stringified);
      } else {
        urlSearchParams.delete('filter');
      }
      urlSearchParams.set('page', `${PAGINATION_DEFAULT_PAGE}`);
      history.push({ search: urlSearchParams.toString() });

      if (availableFilter) {
        const labels = helpers.getFilterLabelsAsStr(value, availableFilter);
        GAonInnovationFilterSubmit(labels);
      }
    },
    [availableFilter, history, location.search]
  );

  const handleSearchSubmit = useCallback(
    (value: string) => {
      const stringified = helpers.stringifySearch(value);
      const urlSearchParams = new URLSearchParams(location.search);
      if (stringified) {
        urlSearchParams.set('search', stringified);
      } else {
        urlSearchParams.delete('search');
      }
      urlSearchParams.set('page', `${PAGINATION_DEFAULT_PAGE}`);
      history.push({ search: urlSearchParams.toString() });
      GAonInnovationSearchSubmit(value);
    },
    [history, location.search]
  );

  // Initialize filter and list
  useEffect(() => {
    if (availableFilter !== null) {
      return;
    }
    async function initFilterAndList() {
      const initAvailableFilter = await fetchAvailableFilter();
      setAvailableFilter(initAvailableFilter);

      const initList = await fetchList({
        filter: activeParams.filter,
        search: activeParams.search,
        page: activeParams.page,
        limit: activeParams.limit,
      });
      setList(initList);
    }
    initFilterAndList();
  }, [activeParams, availableFilter, fetchAvailableFilter, fetchList]);

  // Fetch new list when "activeParams" changes
  useEffect(() => {
    if (!prevActiveParams) {
      return;
    }
    if (
      prevActiveParams.page === activeParams.page &&
      prevActiveParams.limit === activeParams.limit &&
      prevActiveParams.search === activeParams.search &&
      prevActiveParams.filterStr === activeParams.filterStr
    ) {
      return;
    }
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    const params: InnovationLearningItemsRequestParams = {
      page: activeParams.page,
      limit: activeParams.limit,
      search: activeParams.search,
      filter: activeParams.filter,
    };
    const callback = (newList: {
      items: InnovationLearningListingItem[];
      total: number;
    }) => {
      setList(newList);
    };
    fetchListWithDebounce(params, callback);
  }, [activeParams, fetchListWithDebounce, prevActiveParams]);

  const filterElement = useMemo(() => {
    if (isAvailableFilterLoading) {
      return (
        <LearningFilterSkeleton sx={{ width: { xs: '100%', md: '30%' } }} />
      );
    }
    if (!availableFilter) {
      return null;
    }
    return (
      <LearningFilter
        activeFilter={activeParams.filter}
        availableFilter={availableFilter}
        onChange={handleFilterChange}
        sx={{ width: { xs: '100%', md: '30%' } }}
      />
    );
  }, [
    activeParams.filter,
    availableFilter,
    handleFilterChange,
    isAvailableFilterLoading,
  ]);

  const listElement = useMemo(() => {
    if (isListLoading || isAvailableFilterLoading) {
      return <ArticleListSkeleton />;
    }
    if (isListError) {
      return (
        <LearningEmpty>
          <Typography>{translations.learningListFetchError}</Typography>
        </LearningEmpty>
      );
    }
    if (!list) {
      return null;
    }
    if (!list.items?.length) {
      return (
        <LearningEmpty>
          <Typography>{translations.learningListEmpty}</Typography>
          {isPageOutOfBound && (
            <Typography>
              {translations.learningListEmptyDueToPagination}
            </Typography>
          )}
        </LearningEmpty>
      );
    }
    return (
      <ArticleList elementId="learning-article-list" articles={list.items} />
    );
  }, [
    isAvailableFilterLoading,
    isListError,
    isListLoading,
    isPageOutOfBound,
    list,
  ]);

  const paginationElement = useMemo(() => {
    if (
      isMobile &&
      list &&
      list.total <= activeParams.limit &&
      !isPageOutOfBound
    ) {
      return null;
    }

    return (
      <TablePagination
        elementId="learning-article-pagination"
        hideRowsPerPage={isMobile}
        disabled={isListLoading}
        page={activeParams.page}
        rowsPerPage={activeParams.limit}
        rowsPerPageOptions={PAGINATION_LIMITS}
        count={pageCount}
        sx={(theme) => ({
          backgroundColor: theme.colors.surface.primary,
          borderRadius: theme.border.radius.md,
        })}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleLimitChange}
      />
    );
  }, [
    activeParams.limit,
    activeParams.page,
    handleLimitChange,
    handlePageChange,
    isListLoading,
    isMobile,
    isPageOutOfBound,
    list,
    pageCount,
  ]);

  return (
    <Page title={documentTitle}>
      <Stack
        sx={(theme) => ({
          minHeight: '90vh',
          gap: { xs: theme.spacing('xxs'), md: theme.spacing('md') },
        })}
      >
        <Stack
          sx={(theme) => ({
            flexDirection: { xs: 'column', md: 'row' },
            justifyContent: 'space-between',
            gap: theme.spacing('xxs'),
            marginBottom: theme.spacing('xxxs'),
          })}
        >
          <PageTitle
            display={translations.learning}
            hidden={documentTitle.join(' | ')}
          />
          <LearningSearch
            activeSearch={activeParams.search}
            onSubmit={handleSearchSubmit}
          />
        </Stack>
        <Stack
          sx={(theme) => ({
            flexDirection: { xs: 'column', md: 'row' },
            gap: { xs: theme.spacing('sm'), md: '2rem' },
          })}
        >
          {filterElement}
          <Stack
            sx={(theme) => ({
              flexGrow: 1,
              gap: theme.spacing('sm'),
              marginBottom: theme.spacing('sm'),
            })}
          >
            {listElement}
            {paginationElement}
          </Stack>
        </Stack>
      </Stack>
    </Page>
  );
};

export { Learning };
