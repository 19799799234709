import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Collapse from '@mui/material/Collapse';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { Icon } from 'componentsNew/Icon/Icon';
import { forwardRef, useMemo } from 'react';
import { TransitionGroup } from 'react-transition-group';
import { translations } from 'translations';

import { EmbedVideoAdd } from './EmbedInput/EmbedVideoAdd';
import { EmbedVideoEdit } from './EmbedInput/EmbedVideoEdit';
import { EmbedVideoValue } from './EmbedInput/EmbedVideoModal';
import { ImageAdd } from './ImageInput/ImageAdd';
import { ImageDetailsValue } from './ImageInput/ImageDetailsModal';
import { ImageEdit } from './ImageInput/ImageEdit';

export const MEDIA_IMAGE_MIN_WIDTH = 1050;
export const MEDIA_IMAGE_MIN_HEIGHT = 590;
export const MEDIA_IMAGE_ASPECT_RATIO = 1050 / 590;

export type MediaValue = {
  imageDetails?: ImageDetailsValue | null;
  embedVideo?: EmbedVideoValue | null;
};

export type MediaInputProps = {
  elementId?: string;
  value: MediaValue;
  error?: boolean;
  aspectRatio?: number;
  minWidth?: number;
  minHeight?: number;
  hideImageInput?: boolean;
  hideVideoInput?: boolean;
  ariaDescribedBy?: string;
  onChange: (value: MediaValue) => void;
};

const MediaInput = forwardRef<HTMLDivElement, MediaInputProps>(
  (
    {
      elementId = 'media-input',
      value,
      error,
      aspectRatio = MEDIA_IMAGE_ASPECT_RATIO,
      minWidth = MEDIA_IMAGE_MIN_WIDTH,
      minHeight = MEDIA_IMAGE_MIN_HEIGHT,
      hideImageInput,
      hideVideoInput,
      ariaDescribedBy,
      onChange,
    },
    ref
  ) => {
    const currentView = useMemo(() => {
      if (value.imageDetails && !hideImageInput) {
        return 'image';
      }
      if (value.embedVideo && !hideVideoInput) {
        return 'video';
      }
      return 'initial';
    }, [hideImageInput, hideVideoInput, value.embedVideo, value.imageDetails]);

    if (hideImageInput && hideVideoInput) {
      return null;
    }

    return (
      <>
        <TransitionGroup>
          {currentView === 'image' && value.imageDetails && (
            <Collapse>
              <ImageEdit
                elementId={`${elementId}-image-edit`}
                value={value.imageDetails}
                aspectRatio={aspectRatio}
                minWidth={minWidth}
                minHeight={minHeight}
                onChange={(imageDetails) =>
                  onChange({ ...value, imageDetails })
                }
                onDelete={() => onChange({ ...value, imageDetails: null })}
              />
            </Collapse>
          )}
          {currentView === 'video' && value.embedVideo && (
            <Collapse>
              <EmbedVideoEdit
                elementId={`${elementId}-video-edit`}
                value={value.embedVideo}
                onChange={(embedVideo) => onChange({ ...value, embedVideo })}
                onDelete={() => onChange({ ...value, embedVideo: null })}
              />
            </Collapse>
          )}
          {currentView === 'initial' && (
            <Collapse>
              <Stack
                ref={ref}
                aria-describedby={ariaDescribedBy}
                sx={(theme) => ({
                  padding: theme.spacing('md'),
                  border: `1px dashed ${theme.colors.border.input}`,
                  borderRadius: theme.border.radius.sm,
                  gap: theme.spacing('xs'),
                  ...(error && {
                    border: `1px solid ${theme.colors.border.critical}`,
                  }),
                })}
              >
                <Stack
                  sx={(theme) => ({
                    flexDirection: 'row',
                    alignItems: 'center',
                    gap: theme.spacing('xs'),
                    flexWrap: 'wrap',
                  })}
                >
                  {!hideImageInput && !hideVideoInput && (
                    <Typography
                      sx={(theme) => ({
                        color: theme.colors.text.brand,
                        fontWeight: theme.typography.fontWeightBold,
                      })}
                    >
                      {translations.add}
                    </Typography>
                  )}
                  {!hideImageInput && (
                    <ImageAdd
                      elementId={`${elementId}-image-add`}
                      aspectRatio={aspectRatio}
                      minWidth={minWidth}
                      minHeight={minHeight}
                      onSubmit={(imageDetails) =>
                        onChange({ ...value, imageDetails })
                      }
                      triggerElement={
                        <Button
                          variant="outlined"
                          endIcon={<Icon type="photo" color="brandBase" />}
                        >
                          {translations.mediaInputImage}
                        </Button>
                      }
                    />
                  )}
                  {!hideImageInput && !hideVideoInput && (
                    <Typography
                      sx={(theme) => ({
                        color: theme.colors.text.brand,
                        fontWeight: theme.typography.fontWeightBold,
                      })}
                    >
                      {translations.or}
                    </Typography>
                  )}
                  {!hideVideoInput && (
                    <EmbedVideoAdd
                      elementId={`${elementId}-image-add`}
                      onSubmit={(embedVideo) =>
                        onChange({ ...value, embedVideo })
                      }
                      triggerElement={
                        <Button
                          variant="outlined"
                          endIcon={<Icon type="code" color="brandBase" />}
                        >
                          {translations.mediaInputVideo}
                        </Button>
                      }
                    />
                  )}
                </Stack>
                <Box
                  component="ul"
                  sx={(theme) => ({
                    margin: `${theme.spacing('xxxs')} ${theme.spacing('sm')}`,
                    paddingInlineStart: 0,
                    marginBlockStart: 0,
                    marginBlockEnd: 0,
                    li: {
                      color: theme.colors.text.tertiary,
                      '&::marker': {
                        fontSize: '0.75em',
                      },
                    },
                  })}
                >
                  {!hideImageInput && (
                    <Typography
                      variant="caption"
                      component="li"
                      dangerouslySetInnerHTML={{
                        __html: translations.mediaInputImageInfo,
                      }}
                    />
                  )}
                  {!hideVideoInput && (
                    <Typography
                      variant="caption"
                      component="li"
                      dangerouslySetInnerHTML={{
                        __html: translations.mediaInputVideoInfo,
                      }}
                    />
                  )}
                </Box>
              </Stack>
            </Collapse>
          )}
        </TransitionGroup>
      </>
    );
  }
);

export { MediaInput };
