import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { InnovationTrainingArticle } from 'api/cms-innovation/types';
import * as findApi from 'api/find';
import { InnovationLearningListingItem } from 'api/find/types';
import { LinkList, LinkListItem } from 'componentsNew/LinkList';
import { PageContentHeader } from 'layout';
import { useEffect, useMemo, useState } from 'react';
import { translations } from 'translations';
import { getDateOrDaysAgo } from 'utils/misc/time';

import {
  getInnovationListingItemLinkUrl,
  INNOVATION_LISTING_ITEM_TYPE_TO_LABEL,
} from '../helpers';

type RelatedArticlesProps = {
  elementId: string;
  article: InnovationTrainingArticle;
  limit?: number;
  onClick?: (article: InnovationLearningListingItem) => void;
};

const RelatedArticles = ({
  elementId,
  article,
  limit = 5,
  onClick,
}: RelatedArticlesProps) => {
  const [relatedArticles, setRelatedArticles] = useState<
    InnovationLearningListingItem[] | null
  >(null);

  const listItems = useMemo(() => {
    if (!relatedArticles) {
      return [];
    }
    return relatedArticles
      .filter((relatedArticle) => relatedArticle.id !== article.id)
      .map((relatedArticle, index) => {
        const item: LinkListItem = {
          id: `${elementId}-item-${index}`,
          to: getInnovationListingItemLinkUrl(
            relatedArticle.id,
            relatedArticle.type
          ),
          onClick: () => {
            if (onClick) {
              onClick(relatedArticle);
            }
          },
          divider: true,
          contentElement: (
            <Stack sx={{ width: '100%' }}>
              <Typography
                variant="caption"
                sx={(theme) => ({
                  color: theme.colors.text.tertiary,
                })}
              >
                {INNOVATION_LISTING_ITEM_TYPE_TO_LABEL[relatedArticle.type]}
              </Typography>
              <Typography
                variant="body2"
                sx={(theme) => ({
                  color: theme.colors.text.secondary,
                  fontWeight: theme.typography.fontWeightBold,
                })}
              >
                {relatedArticle.title}
              </Typography>
            </Stack>
          ),
          endElement: (
            <Typography
              variant="caption"
              sx={(theme) => ({
                display: 'block',
                maxWidth: '6rem',
                textAlign: 'right',
                color: theme.colors.text.tertiary,
              })}
            >
              {getDateOrDaysAgo(relatedArticle.lastModifiedDate)}
            </Typography>
          ),
        };
        return item;
      });
  }, [relatedArticles, article.id, elementId, onClick]);

  useEffect(() => {
    const fetchRelatedArticles = async () => {
      let articles: InnovationLearningListingItem[] = [];
      try {
        const query = findApi.getInnovationLearningItemsQueryString({
          limit: limit,
          filter: {
            focusAreas: article.focusAreas.map((focusArea) => focusArea.id),
          },
        });
        const response = await findApi.getInnovationLearningItems(query);
        const data = response?.data?.data;
        if (data) {
          articles = data.items as InnovationLearningListingItem[];
        }
      } catch {
      } finally {
        setRelatedArticles(articles);
      }
    };
    fetchRelatedArticles();
  }, [article.focusAreas, limit]);

  if (!listItems.length) {
    return null;
  }

  return (
    <Stack id={elementId} sx={(theme) => ({ gap: theme.spacing('sm') })}>
      <PageContentHeader text={translations.related} />
      <LinkList items={listItems} />
    </Stack>
  );
};

export { RelatedArticles };
